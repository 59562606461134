import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import PropTypes from "prop-types"
import ImageUploadModal from "./uploadimage";

import "react-datepicker/dist/react-datepicker.css"
import ProductWebshot from "./productShot";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { patchProduct, getTypes, getMaterials, getProducts } from "../../helpers/api_helper"
import { getCustomers as onGetCustomers } from "store/actions"
import CustomerSelect from "pages/Orders/customersmodal";
import { useSelector, useDispatch } from "react-redux"




const ProductUpdate = (props) => {



  document.title = "Create Task | kerino - React Admin & Dashboard Template";

  const dispatch = useDispatch()
  const [name, setname] = useState()
  const [prdid, setprdid] = useState()
  const [materialsList, setMaterialsList] = useState([])

  const [open, setOpen] = useState(false);
  const [canlended, setcanlended] = useState(false);
  const [sku, setsku] = useState()
  const [provsku, setprovsku] = useState()
  const [vat, setvat] = useState()
  const [description, setdescription] = useState()
  const [b2bsalesprice, setb2bsalesprice] = useState()
  const [salesprice, setsalesprice] = useState()
  const [vatClass, setvatClass] = useState()
  const [stock, setstock] = useState(0)
  const [unit, setunit] = useState()
  const [types, settypes] = useState([])
  const [category, setcategory] = useState([])
  const [steps, setstep] = useState([])
  const [selectedStep, setSelectedStep] = useState([])
  const [usageType, setusageType] = useState("")
  const [overideable, setoveridable] = useState(false)
  const [type, settype] = useState("GEMISMA")
  const [costprice, setcostprice] = useState()
  const [costmargin, setcostmargin] = useState()
  const [productPhotos, setproductPhotos] = useState([])
  const [search, setSearch] = useState('');
  const [filteredMaterials, setFilteredMaterials] = useState([])
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [products, setProducts] = useState([])
  const [matsToreplace, setmatsToreplace] = useState([])
  const [customerid, setcustomerid] = useState("")
  const [customername, setcustomername] = useState("")


  const [isLoading, setisLoading] = useState(false)

  const [loader, setLoader] = useState("")
  // useEffect(() => {
  //   getProducts().then(res => setProducts(res))
  // }, [])
  function getCustomerById(id) {
    return customers.find(customer => customer.id === id);
  }

  useEffect(() => {
    const selcustomer = customerid && getCustomerById(customerid)
    setcustomername(selcustomer.name)
  }, [customerid])


  async function replaceMaterial(replacementProduct) {
    try {
        const products = await getProducts(); // Wait for products to be fetched
        let allRequestsCompleted = true;
        setisLoading(true); // Initialize the loading state
        setLoader("Updating all products please wait ... ");
        let materialReplaced = false; // Flag to track if any replacement occurred
        const matsToReplace = [];
        const patchPromises = []; // Array to hold all patch promises

        for (const product of products) {
            if (product.requirements) {
                const requirements = JSON.parse(product.requirements);

                for (const requirement of requirements) {
                    if (requirement.materials) {
                        const materialIndex = requirement.materials.findIndex(material => material.id === replacementProduct.id);
                        
                        if (materialIndex !== -1) {
                            // Replace the material with the replacementProduct, keeping the old quantity
                            requirement.materials[materialIndex] = {
                                ...replacementProduct,
                                quantity: requirement.materials[materialIndex].quantity,
                            };

                            if (product.usageType === "MATERIAL") {
                                matsToReplace.push(product);
                            }

                            materialReplaced = true; // Set the flag to true
                        }

                        if (materialReplaced) { // Check if material has been replaced before making the patch call
                            // Update the requirements array within the product
                            product.requirements = JSON.stringify(requirements);

                            const payload = {
                                "resource": [product],
                            };

                     

                            // Push the patch request promise into the array
                            const patchPromise = patchProduct(payload)
                            
                                .then(res => {
                                    setLoader("Updating:" + product.name);
                                    if (res && res.error) {
                                        allRequestsCompleted = false;
                                    }
                                })
                                .catch(error => {
                                    allRequestsCompleted = false;
                                    console.error(error);
                                });

                            patchPromises.push(patchPromise);
                        }
                    }
                }
            }
        }

        // Wait for all patch requests to complete
        await Promise.all(patchPromises);

   
        if (allRequestsCompleted) {
            setLoader("ALL PRODUCTS ARE UPADTED");
            setisLoading(false); // Initialize the loading state
            console.log("All patch requests completed successfully.");
           // setisLoading(false); // Stop loading state
        } else {
            console.log("Some patch requests failed.");
        }
    } catch (error) {
        console.error("An error occurred:", error);
        setisLoading(false);
    }
}

const { customers } = useSelector(state => ({
  customers: state.customers.customers
}))

useEffect(() => {
  dispatch(onGetCustomers())
}, [dispatch])

  useEffect(() => {
    if (usageType != "PURCHASED-MATERIAL") {
      totalCost()
    }

  }, [steps, costmargin])

  const handleDelete = (index) => {
    const updatedPhotos = [...productPhotos];
    updatedPhotos.splice(index, 1);
    setproductPhotos(updatedPhotos);
  };


  useEffect(() => {
    if (search) {
      const searchRegex = new RegExp(search, 'i');
      const filtered = materialsList && materialsList.filter((cust) =>
        searchRegex.test(cust.name) ||
        searchRegex.test(cust.description)
      )
      setFilteredMaterials(filtered);
    } else {
      setFilteredMaterials(materialsList);
    }
  }, [search, materialsList]);


  useEffect(() => {
    const prd = props.history.location.state && props.history.location.state.product;
    //console.log(props.history)
    if (prd) {
      //console.log(prd)
      setname(prd.name)
      setsku(prd.sku)
      setvat(prd.vat)
      setdescription(prd.description)
      setb2bsalesprice(prd.b2bsalesprice)
      setsalesprice(prd.salesprice)
      prd.productPhotos && setproductPhotos(JSON.parse(prd.productPhotos))
      setvatClass(prd.vatClass)
      setstock(prd.stock)
      setunit(prd.unit)
      setcategory(prd.category)
      setusageType(prd.usageType)
      setstep(JSON.parse(prd.requirements))
      setprdid(prd.id)
      setcostmargin(prd.costmargin)
      totalCost()
      setcostprice(prd.costprice)
      setoveridable(prd.overidable)
      setcanlended(prd.canlended)
    }

  }, [])


  const totalCost = () => {
    const matesum = []
    steps.map((step) => {
      step.materials.map((mats) => {
        getMaterials(mats.id).then(res => {
          const mat = mats.quantity * res[0].costprice;
          matesum.push(mat)
          //console.log(mat)
          const final = matesum.reduce((a, b) => a + b, 0);
          setcostprice(final + (final / 100 * costmargin))
        })
      })
    })
  }

  const deleteStep = (b, i) => {
    const newSteps = [...steps];
    newSteps[i].materials.splice(b, 1);
    setstep(newSteps);
    totalCost();
  };

  // +(costprice / 100 * costmargin)
  useEffect(() => {
    getTypes().then(res => settypes(res))
    getMaterials().then(res => setMaterialsList(res))
  }, [])

  //meta title
  const createProduct = () => {
    const payload = {
      "resource": [

        {
          "id": prdid,
          "name": name,
          "description": description,
          "b2bsalesprice": b2bsalesprice,
          "sku": sku,
          "vat": vat,
          "productPhotos": JSON.stringify(productPhotos),
          "usageType": usageType,
          "salesprice": salesprice,
          "provsku":provsku,
          "costprice": costprice,
          "vatClass": vatClass,
          "stock": stock,
          "unit": unit,
          "category": category,
          "requirements": JSON.stringify(steps),
          "costmargin": costmargin,
          "overidable": overideable,
          "canlended": canlended,
          customer_id:customerid
        }
      ],
    }

    patchProduct(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
      }
      if (res && res.resource[0]) {
        props.history.push("/products")
      }
    })

  }

  function fixColorsByType(usageType) {
    switch (usageType) {
      case 'MATERIAL':
        return '#BCBCEA'; // yellow
      case 'COMPLETE-PRODUCT':
        return '#64F641'; // red
      case 'PURCHASED-MATERIAL':
        return '#F5F8B5'; // red
      default:
        return ''; // empty string (no color)
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Update Product" > </Breadcrumbs>

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            SKU
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={sku}
                              onChange={(e) => setsku(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            PROV SKU
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={provsku}
                              onChange={(e) => setprovsku(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Description
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="area"
                              className="form-control"
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            UsageType
                          </label>
                          <div className="col-lg-10">
                            <Input value={usageType} onChange={(e) => setusageType(e.target.value)} type="select" name="select" id="exampleSelect">
                              <option value={"MATERIAL"}>MATERIAL</option>
                              <option value={"PURCHASED-MATERIAL"}>PURCHASED-MATERIAL</option>
                              <option value={"COMPLETE-PRODUCT"}>COMPLETE PRODUCT</option>


                            </Input>

                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Category
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={category}
                              onChange={(e) => setcategory(e.target.value)}
                              placeholder="Enter Stock"
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Customer Link
                          </label>
                 
                    
                            <Col>
                            <CustomerSelect customers={customers} onSelect={(e) => setcustomerid(e)}>
                            </CustomerSelect>
                            </Col>
                            <div>{customername}</div>
                            <Col>
                            </Col>
                
          
                   
            
              
                        </FormGroup>

                


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Vat
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={vat}
                              onChange={(e) => setvat(e.target.value)}
                              placeholder="Enter Values..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            b2bsalesprice
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={b2bsalesprice}
                              onChange={(e) => setb2bsalesprice(e.target.value)}
                              placeholder="Enter Values..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Sales Price
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={salesprice}
                              onChange={(e) => setsalesprice(e.target.value)}
                              placeholder="Enter Values..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Cost Margin
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={costmargin}
                              onChange={(e) => setcostmargin(e.target.value)}
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Cost Price
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget2"
                              name="taskbudget2"
                              type="text"
                              value={costprice}
                              onChange={(e) => {
                                usageType === "PURCHASED-MATERIAL" && setcostprice(e.target.value)
                                // console.log(costprice)
                              }}

                              className="form-control"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            VatClass
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={vatClass}
                              onChange={(e) => setvatClass(e.target.value)}
                              placeholder="Enter Values..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

                        {/* <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Stock
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="number"
                              value={stock}
                              onChange={(e) => setstock(e.target.value)}
                              placeholder="Enter Stock"
                              className="form-control"
                            />
                          </div>
                        </FormGroup> */}


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Unit
                          </label>
                          <div className="col-lg-10">
                            <Input value={unit} onChange={(e) => setunit(e.target.value)} type="select" name="select" id="exampleSelect">
                              <option value={"KG"}>Killos</option>
                              <option value={"Meter"}>Meter</option>
                              <option value={"CM"}>Centimeters</option>
                              <option value={"MM"}>Milimmetes</option>
                              <option value={"Litter"}>Litter</option>
                              <option value={"CubicM"}>CubicM</option>
                              <option value={"Item"}>Item</option>
                              <option value={"Gram"}>Gram</option>
                            </Input>

                          </div>
                        </FormGroup>
                        <Row>
                          <Col>    <FormGroup check>
                            <Label check>
                              <Input
                                checked={overideable}
                                onChange={(e) => setoveridable(e.target.checked)}
                                type="checkbox" />{' '}
                              Product can be Overided on Tasks
                            </Label>
                          </FormGroup></Col>
                          <Col>

                            <FormGroup check>
                              <Label check>
                                <Input
                                  checked={canlended}
                                  onChange={(e) => setcanlended(e.target.checked)}
                                  type="checkbox" />{' '}
                                Product can be Lended
                              </Label>
                            </FormGroup></Col>
                        </Row>


                      </div>
                    </div>
                  </form>

                </CardBody>
              </Card>
            </Col>
            <Col>


            {usageType !== "PURCHASED-MATERIAL" && (
  <Card style={{ border: '2px solid #dee2e6', marginTop: '20px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
    <CardBody style={{ padding: '20px' }}>
      <Row style={{ marginBottom: '10px' }}>
        <h5 style={{ color: '#007bff' }}>Product Cost: {costprice}</h5>
      </Row>

      <Row style={{ marginBottom: '20px' }}>
        <Col>
          <Input 
            onChange={(e) => settype(e.target.value)} 
            type="select" 
            name="select" 
            id="exampleSelect" 
            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ced4da' }}
          >
            {types && types.map((tip, i) => (
              <option value={tip.name} key={i}>{tip.name}</option>
            ))}
          </Input>
        </Col>
        <Col>
          <Button 
            onClick={() => setstep(steps => [...steps, { seq: steps.length + 1, typid: type, materials: [] }])} 
            style={{ backgroundColor: '#28a745', color: 'white', padding: '10px 15px', borderRadius: '5px', border: 'none' }}
          >
            Add PRD Step
          </Button>
        </Col>
      </Row>

      <Row>
        {steps && steps.map((step, i) => (
          <div key={i} style={{ border: '2px solid #28a745', marginBottom: '10px', padding: '15px', borderRadius: '5px', backgroundColor: '#f9f9f9' }}>
            <Row style={{ marginBottom: '10px' }}>
              <Col>
                <h3 style={{ fontWeight: 'bold', color: '#28a745' }}>{step.typid}</h3>
                <h5 style={{ color: '#6c757d' }}>Order: {step.seq}</h5>
              </Col>
              <Col lg="2">
                <Button 
                  style={{ backgroundColor: '#007bff', color: 'white', padding: '8px 12px', borderRadius: '5px', border: 'none' }} 
                  className="bx bx-plus" 
                  onClick={() => {
                    setSelectedStep(i);
                    setOpen(true);
                  }}
                >
                  Materials
                </Button>
              </Col>
              <Col lg="1">
                <i
                  style={{ fontSize: '20px', color: '#dc3545', cursor: 'pointer' }}
                  className="bx bx-trash-alt"
                  onClick={() => {
                    const updatedSteps = steps.filter((item, ind) => ind !== i);
                    step.materials = [];
                    setstep(updatedSteps);
                    setcostprice(0);
                    totalCost();
                  }}
                />
              </Col>
            </Row>

            <Row>
              {step.materials && step.materials.map((mat, b) => (
                <Row key={i} style={{ marginBottom: '10px' }}>
                  <Col lg="5">
                    <h5 style={{ fontWeight: 'normal', color: '#343a40' }}>{mat.name}</h5>
                  </Col>
                  <Col lg="2">
                    <span style={{ color: '#6c757d' }}>{mat.unit}</span>
                  </Col>
                  <Col lg="1">
                    {mat.overidable && (
                      <i 
                        style={{ fontSize: '25px', color: '#28a745' }} 
                        className="bx bx-copy"
                      />
                    )}
                  </Col>
                  <Col lg="2">
                    <Input
                      defaultValue={mat.quantity}
                      onChange={(e) => {
                        totalCost();
                        mat.quantity = e.target.value.replace(",", ".");
                      }}
                      style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ced4da' }}
                    />
                  </Col>
                  <Col lg="1">
                    <i
                      style={{ fontSize: '25px', color: '#dc3545', cursor: 'pointer' }}
                      className="bx bx-trash-alt"
                      onClick={() => {
                        deleteStep(b, i);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </Row>
          </div>
        ))}
      </Row>
    </CardBody>
  </Card>
)}

              <FormGroup className="mb-4" row>
                <Label
                  htmlFor="taskname"
                  className="col-form-label col-lg-2"
                >
                  Product Photos
                </Label>


                <Card>
                  <Row>
                    <Col>
                      <ProductWebshot taskPhotos={productPhotos} setTaskPhotos={setproductPhotos} ></ProductWebshot>
                    </Col>
                    <Col>
                      <ImageUploadModal onImageUpload={(newImage) => setproductPhotos([...productPhotos, newImage])}></ImageUploadModal>
                    </Col>
                  </Row>



                  {productPhotos && productPhotos.map((img, i) => (
                    <div key={i} style={{ position: 'relative', display: 'inline-block', margin: 10 }}>
                      <img src={img} alt="webcam preview" style={{ width: '150px', height: '150px' }} />
                      <button style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDelete(i)}>Trash</button>
                    </div>
                  ))}

                </Card>
              </FormGroup>

              <Row className="justify-content-end">
              { !isLoading &&   <Col lg="10">
                  { <Button onClick={() => {
                    createProduct()
                  }} type="submit" color="primary">
                    Update product
                  </Button>}
                  {
                    <Button style={{marginLeft:10}}onClick={() => {
                      replaceMaterial(props.history.location.state.product, products).then(res => matsToreplace.map(mat => 
                        replaceMaterial(mat, products)
                      ) )
                    }} type="submit" color="primary">
                      Update ALL
                    </Button>
                  }
                  {<Button onClick={() => props.history.push("/products")}>Close </Button>}
                  {costprice < 0 || !costprice && <div style={{ color: "red", fontSize: 18 }}>Cost price is not Correct ! </div>}
                  {steps[0] && steps[0].seq !== 1 && <div style={{ color: "red", fontSize: 18 }}>Order Of Section Steps Should Start with Number 1 </div>}

                </Col>} 
              </Row>
              {isLoading && <div> {loader}</div>}
            </Col>
          </Row>


        </Container>
        <ReactDrawer
          open={open}
          position={'right'}
          onClose={() => setOpen(false)}
          style={{ width: 'calc(100% + 20px)' }}
        >
          <Row style={{ margin: 10 }}>
            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search"></Input>
          </Row>
          <div style={{ margin: 10, maxHeight: '90%', overflowY: 'auto' }}>
            <Row style={{ margin: 10, backgroundColor: '#f9f9c4', borderTop: '2px solid #ddd' }}>
              <Col lg="10">Description</Col>
              <Col lg="2">Units</Col>
            </Row>
            {filteredMaterials && filteredMaterials.map((prd, i) => {


              return (
                <Row
                  key={i}
                  onMouseEnter={() => setHoveredRowIndex(i)}
                  onMouseLeave={() => setHoveredRowIndex(null)}
                  onClick={() => {
                    Object.assign(prd, { quantity: 0 })
                    steps[selectedStep].materials.push(prd)
                    setOpen(false)
                  }}
                  style={{
                    backgroundColor: fixColorsByType(prd.usageType),
                    fontWeight: hoveredRowIndex === i ? 'bold' : 18,
                    fontSize: hoveredRowIndex === i ? 'larger' : 12
                  }}
                  id={`row${i}`}
                >
                  <Col lg="9">{prd.usageType === "MATERIAL" && <i style={{ marginRight: 4 }} className="bx bx-brush" />}
                    {prd.usageType === "PURCHASED-MATERIAL" && <i style={{ marginRight: 4 }} className="bx bxs-chip" />}
                    {prd.usageType === "COMPLETE-PRODUCT" && <i style={{ marginRight: 4 }} className="bx bxl-digitalocean" />}
                    {prd.name}</Col>
                  <Col lg="2">{prd.unit}</Col>
                </Row>
              );
            })}
          </div>
        </ReactDrawer>

      </div>
    </>
  )
}
ProductUpdate.propTypes = {
  history: PropTypes.object,
}

export default ProductUpdate
