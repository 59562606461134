import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Collapse,
  Table,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getProviders } from "helpers/api_helper";
import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { propTypes } from "google-map-react";

const ProviderDetail = () => {
  document.title = "Προφίλ Παρόχου | React Admin & Dashboard Template";
  const history = useHistory();
  const { id } = useParams();
  const [provider, setProvider] = useState(null);
  const [error, setError] = useState(null);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);


  useEffect(() => {
    if (!provider) return;

    const totalOrders = filteredData.reduce((sum, entry) => sum + (entry.buy ? parseFloat(entry.buy) : 0), 0);
    const totalPayments = filteredData.reduce((sum, entry) => sum + (entry.payment ? Math.abs(parseFloat(entry.payment)) : 0), 0);
    const totalBalance = totalOrders - totalPayments;

    setTotalOrders(totalOrders);
    setTotalPayments(totalPayments);
    setTotalBalance(totalBalance);
  }, [filteredData]);

  useEffect(() => {
    if (id) {
      getProviders(id)
        .then((res) => {
          if (res && res[0]) {
            setProvider(res[0]);
          } else {
            setError("Ο πάροχος δεν βρέθηκε");
          }
        })
        .catch(() => setError("Σφάλμα κατά τη λήψη των στοιχείων του παρόχου"));
    }
  }, [id]);

  useEffect(() => {
    if (!provider) return;
    const orders = provider.orders || [];
    const payments = provider.payments || [];

    const fromTimestamp = dateFrom ? new Date(dateFrom).getTime() : 0;
    const toTimestamp = dateTo ? new Date(dateTo).getTime() : Infinity;

    const formattedOrders = orders
      .map((order) => ({
        date: new Date(order.importDate).getTime(),
        type: "Παραγγελία",
        payment: "",
        orderid: order.id,
        buy: JSON.parse(order.totals).totalOrder, // Buy amount
        refcode: order.refcode,
      }))
      .filter((entry) => entry.date >= fromTimestamp && entry.date <= toTimestamp);

    const formattedPayments = payments
      .map((payment) => ({
        date: new Date(payment.date).getTime(),
        type: "Πληρωμή",
        payment: -Math.abs(payment.ammount), // Payments are negative
        buy: "",
        orderid: payment.id,
        refcode: payment.refcode,
      }))
      .filter((entry) => entry.date >= fromTimestamp && entry.date <= toTimestamp);

    const combinedData = [...formattedOrders, ...formattedPayments];

    // Apply sorting
    combinedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });

    setFilteredData(combinedData);
  }, [provider, dateFrom, dateTo, sortField, sortOrder]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Πάροχος" breadcrumbItem="Provider Detail" />
          {error && <Alert color="danger">{error}</Alert>}

          {/* Customer Details Card */}

          {provider && (
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="d-flex">
                      <div className="ms-3">
                        <img
                          src={avatar}
                          alt="Εικόνα Παρόχου"
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{provider.name}</h5>
                          <p className="mb-1">{provider.email}</p>
                          <p className="mb-0">ID: #{provider.id}</p>
                          <p className="mb-0">VAT: {provider.vat}</p>
                          <p className="mb-0">Phone: {provider.phone}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>


 
                        <Row>
                          <Col md="4">
                            <Card className="border p-3">
                              <h5>Συνολικές Πληρωμές</h5>
                              <h4 className="text-danger">{totalPayments.toFixed(2)} €</h4>
                            </Card>
                          </Col>
                          <Col md="4">
                            <Card className="border p-3">
                              <h5>Συνολικές Παραγγελίες</h5>
                              <h4 className="text-success">{totalOrders.toFixed(2)} €</h4>
                            </Card>
                          </Col>
                          <Col md="4">
                            <Card className="border p-3">
                              <h5>Υπόλοιπο</h5>
                              <h4 className="text-primary">{totalBalance.toFixed(2)} €</h4>
                            </Card>
                          </Col>
                        </Row>

                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}


          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Συγκεντρωτική Λίστα           <Button color="primary" onClick={() => setIsReportOpen(!isReportOpen)}>
                    {isReportOpen ? "Απόκρυψη" : "Φιλτράρισμα"}
                  </Button>
</h4>
              <Row>
     

        
                <Collapse isOpen={isReportOpen}>
                <Row>
                  <Col md="6">
                    <Label>Από</Label>
                    <Input type="date" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                  </Col>
                  <Col md="6">
                    <Label>Έως</Label>
                    <Input type="date" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                  </Col>
                </Row>
              </Collapse>
              </Row>
             
              {filteredData.length > 0 ? (
                <div style={{ maxHeight: "600px", overflowY: "auto", border: "1px solid #ddd" }}>


                  <Table >
                    <thead>
                      <tr>
                        <th onClick={() => handleSort("date")} style={{ cursor: "pointer" }}>
                          Ημερομηνία {sortField === "date" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th>Τύπος</th>
                        <th onClick={() => handleSort("payment")} style={{ cursor: "pointer" }}>
                          Πληρωμή {sortField === "payment" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("buy")} style={{ cursor: "pointer" }}>
                          Αγορά {sortField === "buy" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                        <th onClick={() => handleSort("refcode")} style={{ cursor: "pointer" }}>
                          Αναφορά {sortField === "refcode" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((entry, index) => (
                        <tr onClick={() => {
                          if (entry.type != "Πληρωμή") {
                            history.push("/invoice-detail/" + entry.orderid)
                          }
                          if (entry.type == "Πληρωμή")
                            history.push("/payment-overview/" + entry.orderid)
                        }
                        } style={{
                          minHeight: 10, backgroundColor: entry.type === "Πληρωμή" ? "#ffdddd" : "#ddffdd",
                        }} key={index} >
                          <td>{new Date(entry.date).toLocaleDateString()}</td>
                          <td>{entry.type}</td>
                          <td>{entry.payment ? `${entry.payment}€` : "-"}</td>
                          <td>{entry.buy ? `${entry.buy}€` : "-"}</td>
                          <td>{entry.refcode}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <p className="text-center">Δεν βρέθηκαν δεδομένα.</p>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProviderDetail.propTypes = {
  history: PropTypes.object,
};

export default ProviderDetail;
