import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';

// Your Google Maps API key
const GOOGLE_MAPS_API_KEY = 'AIzaSyAvDka_HEWufYVqW_m2KzIfSJEjd9O-gYI';

// The fixed starting and ending address
const START_END_ADDRESS = "Psichari 1, Metamorfosi 144 52, Greece";

const OptimalRouteMap = ({ addresses, onRouteCalculated ,Dontshow=false}) => {
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [totalDistance, setTotalDistance] = useState(''); // Total distance in kilometers
  const [totalDuration, setTotalDuration] = useState(''); // Total time in minutes
  const [routeDetails, setRouteDetails] = useState([]); // List of route details (legs)

  const calculateRoute = (map, maps) => {
    if (addresses.length < 1) {
      console.error('At least one address (besides the starting point) is needed to calculate a route');
      return;
    }

    const allAddresses = [START_END_ADDRESS, ...addresses];
    const directionsService = new maps.DirectionsService();

    let renderer = directionsRenderer;
    if (!renderer) {
      renderer = new maps.DirectionsRenderer();
      renderer.setMap(map);
      setDirectionsRenderer(renderer); // Save in state
    }

    const origin = allAddresses[0];
    const destination = START_END_ADDRESS;
    const waypoints = allAddresses.slice(1).map(address => ({
      location: address,
      stopover: true,
    }));

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        optimizeWaypoints: true,
        travelMode: maps.TravelMode.DRIVING,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: 'bestguess',
        },
      },
      (result, status) => {
        if (status === 'OK') {
          renderer.setDirections(result); // Use the renderer safely
          const route = result.routes[0];

          let totalDistance = 0;
          let totalDuration = 0;
          const details = route.legs.map((leg) => {
            totalDistance += leg.distance.value;
            totalDuration += leg.duration_in_traffic ? leg.duration_in_traffic.value : leg.duration.value;
            return {
              start: leg.start_address,
              end: leg.end_address,
              distance: leg.distance.text,
              duration: leg.duration_in_traffic ? leg.duration_in_traffic.text : leg.duration.text,
            };
          });

          setTotalDistance((totalDistance / 1000).toFixed(2));
          setTotalDuration(Math.floor(totalDuration / 60));
          setRouteDetails(details);

          if (onRouteCalculated) {
            onRouteCalculated(details, (totalDistance / 1000).toFixed(2), Math.floor(totalDuration / 60));
          }
        } else {
          console.error(`Error calculating directions: ${status}`);
        }
      }
    );
  };

  const openGoogleMaps = () => {
    if (routeDetails.length === 0) {
      console.error("Route details are empty");
      return;
    }

    const baseUrl = "https://www.google.com/maps/dir/?api=1";
    const origin = encodeURIComponent(START_END_ADDRESS);
    const destination = encodeURIComponent(START_END_ADDRESS);
    const waypoints = routeDetails.map(leg => encodeURIComponent(leg.end)).join("|");

    const mapsUrl = `${baseUrl}&origin=${origin}&destination=${destination}&waypoints=${waypoints}&travelmode=driving`;

    window.open(mapsUrl, "_blank");
  };

  useEffect(() => {
    if (mapInstance && mapsInstance) {
      calculateRoute(mapInstance, mapsInstance);
    }
  }, [mapInstance, mapsInstance, addresses]);

  return (
    <div>
      {/* Display total distance and time */}
      {totalDistance.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h4>Optimal Route Summary (with Traffic)</h4>
          <p><strong>Total Distance:</strong> {totalDistance} km</p>
          <p><strong>Total Duration:</strong> {totalDuration} minutes</p>
        </div>
      )}

      {!Dontshow && addresses.length > 1 && (
        <div style={{ height: '300px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            defaultCenter={{ lat: 37.9838, lng: 23.7275 }} // Default center (Athens)
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              setMapInstance(map);
              setMapsInstance(maps);
            }}
          />
        </div>
      )}

      {/* List of route details */}
      {Dontshow &&          <button onClick={openGoogleMaps} className="btn btn-primary">
            Open Google Maps
          </button>
          }
      {!Dontshow && routeDetails.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h5>Route Details</h5>
          <ul>
            {routeDetails.map((leg, index) => (
              <li key={index}>
                <strong>From:</strong> {leg.start} <br />
                <strong>To:</strong> {leg.end} <br />
                <strong>Distance:</strong> {leg.distance}, <strong>Duration:</strong> {leg.duration}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

OptimalRouteMap.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  Dontshow:PropTypes.bool,
  onRouteCalculated: PropTypes.func, // Optional callback for sending route details to parent
};

export default OptimalRouteMap;
