import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

const DataChart = ({ totalOrders, totalPaymentsInbound }) => {
  const data = [
    { name: 'Total Orders', value: totalOrders },
    { name: 'Total Payments Inbound', value: totalPaymentsInbound },
  ];

  return (
    <BarChart width={600} height={300} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="value" fill="#8884d8" />
    </BarChart>
  );
};

// Define prop types for DataChart
DataChart.propTypes = {
  totalOrders: PropTypes.number.isRequired,
  totalPaymentsInbound: PropTypes.number.isRequired,
};

export default DataChart;
