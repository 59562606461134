import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Input,
    Button,

} from "reactstrap";
import { postTask } from "helpers/api_helper"

import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
import SectionTable from "./sectionTable";
import { getProducts, patchTask } from "helpers/api_helper"
import { patchProduct, getTypes, getMaterials, postInvoice } from "../../helpers/api_helper"

import WebShot from "./webShot";

const PLYSIMO = (props) => {
    //meta title
    document.title = "Tasks";
    const [selectedTask, setselectedTask] = useState({})
    const [selectedTaskMats, setselectedTaskMats] = useState([])
    const [taskPhotos, setTaskPhotos] = useState([])
    const [quantity, setQuantity] = useState(selectedTask ? selectedTask.quantity : 0);

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };
    const tasks = props.sectiontasks

    function parseDecimalComma(str) {
        if (typeof str !== "string") {
            return NaN;
        }

        return parseFloat(str.replace(",", ".")).toFixed(1);
    }
    useEffect(() => {
        setselectedTask({})
        setselectedTaskMats([])
        setTaskPhotos([])
    }, [props.sectiontasks])


    const finish = (prdid, quantity) => {
        const prdstock = getLStock(prdid).then(stock => {
            const payload1 = {
                "resource": [
                    {
                        "id": prdid,
                        "stock": (Number(stock) + Number(quantity)),
                    }
                ],
            }
            patchProduct(payload1).then(res => {
                if (res && res.error) {
                    //     console.log(res)
                }

                if (res && res.resource[0]) {
                    const payload = {
                        "resource": [
                            {
                                "id": selectedTask.id,
                                // "materials": JSON.stringify(selectedTaskMats),
                                "photos": JSON.stringify(taskPhotos),
                                "status": "FINISHED"
                            }
                        ],
                    }
                    patchTask(payload).then(res => {
                        if (res && res.error) {
                            //      console.log(res)
                        }
                        if (res && res.resource[0]) {
                            //    console.log(res)
                            props.refresh(selectedTask);
                            setQuantity("")
                    
                          
                            // props.history.push("/tasks")

                        }
                    })
                }
            })
        });

    }
    const getLStock = async (id, i) => {
        const stock = await getProducts(id).then(res => res[0])
        return stock.stock
    }
    // function checkStockReceived(payload) {
    //     return payload.every(item => item.stockReceived);
    // }
    // const updateTask = async (taskid) => {
    //     const ifallreceved = checkStockReceived(selectedTaskMats);

    //     const payload = {
    //         "resource": [
    //             {
    //                 "id": taskid,
    //                 "materials": JSON.stringify(selectedTaskMats),
    //                 "status": ifallreceved ? "STOCK-RECEIVED" : "PENDING"
    //             }
    //         ]
    //     };

    //     try {
    //         const res = await patchTask(payload);
    //         if (res && res.error) {
    //             console.log(res);
    //         }
    //         if (res && res.resource[0]) {
    //             console.log(res);

    //             selectedTask.status = ifallreceved ? "STOCK-RECEIVED" : "PENDING"
    //             props.refresh(selectedTask);
    //             props.history.push("/section")
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }






    return (
        <React.Fragment>
                                <Button color="primary" onClick={() =>  props.history.push("/logout")}>
           logout
                    </Button>
            <Card>
                <CardBody>
                    <h5>{props.type && props.type} </h5>
                    <SectionTable clickedItem={(tsk) => {
                        // setselectedTaskMats([])
                        setselectedTask(tsk)
                        // updateCost(JSON.parse(tsk.materials))

                    }} tasks={props.sectiontasks}></SectionTable>
                </CardBody>
            </Card>
            {selectedTask && selectedTask.status === "FINISHED" &&
                <div>
                    <Card>

                        <CardBody> Task has been executed  {selectedTask.updated}</CardBody>
                        <CardBody>  {selectedTask.product.name}</CardBody>
                        {selectedTask.photos && JSON.parse(selectedTask.photos).map((img, i) => (
                            <img key={i} src={img} alt="webcam preview" style={{ margin: 10, width: '150px', height: '150px' }} />
                        ))}
                        <CardBody>  Quantity :{selectedTask.quantity} </CardBody>
                    </Card>
                </div>
            }

            {selectedTask && selectedTask.status === "PENDING" && (
                <div>
                    <Card>
                        <CardBody> Task is on {selectedTask.status}</CardBody>
                        <CardBody> {selectedTask?.product?.name}</CardBody>
                   
                        <CardBody>
                            Washed Items Quantity:
                            <Input
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                style={{ width: '80px', display: 'inline-block', marginLeft: '10px' }}
                            />
                        </CardBody>
                        <Button onClick={() => finish(selectedTask.product.id, quantity)} color="primary">Close task and Move Items to Stock</Button>
                    </Card>
                </div>
            )}

        </React.Fragment>
    );
};
PLYSIMO.propTypes = {
    history: PropTypes.object,
    sectiontasks: PropTypes.array,
    refresh: PropTypes.func,
    type: PropTypes.string,
    allTasks: PropTypes.any


}
export default withRouter(PLYSIMO);
