import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label, Spinner } from "reactstrap";
import { postPayment, getCustomerDetail } from "helpers/api_helper"; // Import getCustomerById
import PropTypes from "prop-types";
import { processCustomerData } from "helpers/generalfunctions";
const PaymentModal = ({ isOpen, toggle, customerId ,customerName}) => {
  const [amount, setAmount] = useState("");
  const [series, setSeries] = useState("");
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);




  
  // Fetch customer details when modal opens
  useEffect(() => {
    if (isOpen && customerId) {
      setLoading(true);
      getCustomerDetail(customerId)
        .then((res) => {
            processCustomerData(res[0]).then(res2 =>    setCustomer(res2))
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching customer:", error);
          setLoading(false);
        });
    }
  }, [isOpen, customerId]);

  console.log(customer)

  const createPayment = () => {
    const payload = {
      resource: [
        {
          bound: "Inbound",
          type: "CASH",
          ammount: amount,
          series: series,
          date: new Date().toISOString(),
          entity_id: customerId,
          entity_name: customerName || "Unknown",
          refcode: "38.00.00",
        },
      ],
    };

    postPayment(payload).then((res) => {
      if (res && res.error) {
        console.error("Payment error:", res.error);
        return;
      }
      if (res && res.resource[0]) {
        toggle(); // Close modal on success
      }
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        <h5>{loading ? <Spinner size="sm" /> : customerName|| "Customer"}</h5>
        <h5>{loading ? <Spinner size="sm" /> : "Balance / Euro: "+ customer?.totalCustomer }</h5>
        <h5>{loading ? <Spinner size="sm" /> : "Recent Payemnt: "+ customer?.lastPaymentDate+" --Euro: "+ customer?.lastPaymentAmount  }</h5>

      </ModalHeader>

      <ModalBody>
        {loading ? (
          <div className="text-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <FormGroup>
            <Label for="series">Receipt Number</Label>
            <Input
              type="text"
              id="series"
              placeholder="Enter series"
              value={series}
              onChange={(e) => setSeries(e.target.value)}
            />

            <Label for="amount">Amount - Euros</Label>
            <Input
              type="number"
              id="amount"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </FormGroup>
        )}
      </ModalBody>

      <ModalFooter>
        <Button color="primary" onClick={createPayment} disabled={!amount || loading}>
          Submit Payment
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PaymentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  customerName:PropTypes.string.isRequired,
};

export default PaymentModal;
