import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import { Modal, ModalHeader, ModalBody, Row, Col, Button, ListGroup, ListGroupItem, Card, CardBody, CardTitle, Input } from 'reactstrap';
import CustomerSelect from 'pages/Orders/customersmodal';
import { postTask, postLoan } from 'helpers/api_helper';

const LoanModal = ({ fetchLoansData, isOpen, customers, setLoanModal, setCustomerId, loans }) => {
  const [customerid, setCustomerid] = useState('');
  const [selectedCustomerLoans, setSelectedCustomerLoans] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [returnedQuantities, setReturnedQuantities] = useState({}); // Store quantities for each product

  useEffect(() => {
    if (isOpen) {
      setSelectedProducts([]);
      setReturnedQuantities({});
      setCustomerid('');
      setSelectedCustomerLoans([]);
    }
  }, [isOpen]);

  // Function to handle individual task and loan creation
  const createTaskAndLoan = async (selectedProduct, returnedQuantity, customerid) => {
    const payload = {
      "resource": [
        {
          "type": "ΠΛΥΝΤΗΡΙΟ",
          "materials": "RETURN",
          "status": "PENDING",
          "orderref": selectedProduct.order_id,
          "productid": selectedProduct.prd_id,
          "name": selectedProduct.prdname,
          "description": "EPISTROFH-PLYSIMO",
          "quantity": parseInt(returnedQuantity),
          "customer_id_ref": customerid,
          "customer_name": selectedProduct.custname,
          "seq": 1
        }
      ],
    };

    try {
      const taskResponse = await postTask(payload);
      if (taskResponse && taskResponse.resource) {
        const loanPayload = {
          "resource": [
            {
              "bound": "INBOUND",
              "quantity": returnedQuantity,
              "customer_id": customerid,
              "order_id": selectedProduct.order_id,
              "prd_id": selectedProduct.prd_id,
              "prdname": selectedProduct.prdname,
              "custname": selectedProduct.custname
            }
          ],
        };
        await postLoan(loanPayload); // Wait for loan call to complete
      }
    } catch (error) {
      console.error('Error in task or loan creation:', error);
    }
  };

  // Handle customer selection and filter loans
  const handleCustomerSelect = (selectedCustomer) => {
    if (!selectedCustomer) {
      console.error('Selected customer is null or undefined');
      return;
    }

    setCustomerid(selectedCustomer);
    setSelectedProducts([]);
    setReturnedQuantities({});

    const filteredLoans = loans
      .filter((loan) => loan.customer_id === selectedCustomer)
      .reduce((acc, loan) => {
        const { order_id, prdname, prd_id, quantity, custname, bound, customer_id } = loan;

        const existingProduct = acc.find((item) => item.prd_id === prd_id);
        console.log(existingProduct)

        if (existingProduct) {
          if (bound === 'OUTBOUND') {
            existingProduct.outbound += quantity;
          } else if (bound === 'INBOUND') {
            existingProduct.inbound += quantity;
          }
          existingProduct.total = existingProduct.outbound - existingProduct.inbound;
        } else {
          acc.push({
            customer_id,
            order_id,
            prdname,
            custname,
            prd_id,
            inbound: bound === 'INBOUND' ? quantity : 0,
            outbound: bound === 'OUTBOUND' ? quantity : 0,
            total: bound === 'OUTBOUND' ? quantity : -quantity,
          });
        }

        return acc;
      }, []);

    setSelectedCustomerLoans(filteredLoans);
  };

  // Handle product selection
  const handleProductSelect = (product) => {
    const isAlreadySelected = selectedProducts.find((p) => p.prd_id === product.prd_id);
    if (!isAlreadySelected) {
      setSelectedProducts([...selectedProducts, product]); // Add product to the selected list
    }
  };

  // Handle quantity change for each product
  const handleQuantityChange = (prd_id, quantity) => {
    setReturnedQuantities({
      ...returnedQuantities,
      [prd_id]: quantity
    });
  };

  // Handle creating returns for all selected products
  const handleCreateReturns = () => {
    selectedProducts.forEach((product) => {
      const returnedQuantity = returnedQuantities[product.prd_id];
      if (returnedQuantity && returnedQuantity > 0) {
        createTaskAndLoan(product, returnedQuantity, customerid);
      }
    });
    setLoanModal(false); // Close the modal after handling the returns
  };

  // Fetch loan data when the modal is closed
  const handleModalClose = () => {
    fetchLoansData();
  };

  return (
    <Modal isOpen={isOpen} onClosed={handleModalClose}>
      <ModalHeader tag="h4">
        <Row style={{ marginLeft: 5 }}>
          <Col>Δημιουργία Τιμολογίου Επιστροφής Εισερχόμενου</Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>Πελάτης:</Col>
          <CustomerSelect customers={customers} onSelect={handleCustomerSelect} />
        </Row>
        {customerid && (
          <Row>
            <Col>Επιλεγμένος Πελάτης: {customerid}</Col>
          </Row>
        )}
        {selectedCustomerLoans.length > 0 && (
          <Row>
            <Col>
              <h5>Δανεισμένα Στοιχεία Πελάτη</h5>
              <ListGroup>
                {selectedCustomerLoans.map((loan) => (
                  <ListGroupItem key={loan.prd_id} onClick={() => handleProductSelect(loan)}>
                    <Row>{loan.prdname} Δανεισμένα: {loan.total}</Row>
                    <Row>
                      ΣΥΝΟΛΟ ΕΙΣΕΡΧΟΜΕΝΟ: {loan.inbound} ΣΥΝΟΛΟ ΕΞΕΡΧΟΜΕΝΟ: {loan.outbound}
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}
        {selectedProducts.length > 0 && (
          <Row>
            <Col>
              {selectedProducts.map((product) => (
                <Card key={product.prd_id}>
                  <CardBody>
                    <CardTitle tag="h5">{product.prdname}</CardTitle>
                    <Input
                      type="number"
                      placeholder="Εισάγετε ποσότητα επιστροφής"
                      value={returnedQuantities[product.prd_id] || ''}
                      onChange={(e) => handleQuantityChange(product.prd_id, e.target.value)}
                    />
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
        )}
        {selectedProducts.length > 0 && (
          <Row>
            <Col>
              <Button color="primary" onClick={handleCreateReturns}>
                Δημιουργία Επιστροφών
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
      <Button onClick={() => setLoanModal(false)}>Κλείσιμο</Button>
    </Modal>
  );
};

// Define prop types for LoanModal
LoanModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  customers: PropTypes.array,
  setLoanModal: PropTypes.func.isRequired,
  setCustomerId: PropTypes.func,
  loans: PropTypes.array.isRequired,
  fetchLoansData: PropTypes.func
};

export default LoanModal;
