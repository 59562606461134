import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"


import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper"


import { postLogin } from "../../../helpers/api_helper"


function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    });

    // Save the response in localStorage
    localStorage.setItem("authUser", JSON.stringify(response));

    if (response.error) {
      yield put(apiError(response.error.message));
    } else {
      yield put(loginSuccess(response));

      // Retrieve the updated user object from localStorage
      const loggedInUser = JSON.parse(localStorage.getItem("authUser"));

      if (loggedInUser) {
        if (loggedInUser.role === "user") {
          history.push("/customers");
        }
        if (loggedInUser.role === "logistirio") {
          history.push("/orders");
        }
        if (loggedInUser.role === "admin") {
          history.push("/customers");
        } else if (loggedInUser.role === "driver") {
          history.push("/driverpage");
        } else {
          history.push("/dashboard"); // Default fallback route
        }
      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

// function* socialLogin({ payload: { data, history, type } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const fireBaseBackend = getFirebaseBackend()
//       const response = yield call(
//         fireBaseBackend.socialLoginUser,
//         data,
//         type,
//       )
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     } else {
//       const response = yield call(postSocialLogin, data)
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     }
//     history.push("/dashboard")
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  // yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
