import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  FormGroup,
  Label,
  Button,
} from "reactstrap"
import ReactDrawer from 'react-drawer';
import PropTypes from "prop-types"

// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postProduct, getTypes, getProducts } from "../../helpers/api_helper"


const ProductCreate = (props) => {

  document.title = "Create Task | kerino - React Admin & Dashboard Template";


  const [name, setname] = useState()
  const [sku, setsku] = useState()
  const [vat, setvat] = useState()
  const [description, setdescription] = useState()
  const [b2bsalesprice, setb2bsalesprice] = useState()
  const [salesprice, setsalesprice] = useState()
  const [vatClass, setvatClass] = useState()
  const [stock, setstock] = useState(0)
  const [unit, setunit] = useState("Item")
  const [category, setcategory] = useState([])
  const [usageType, setusageType] = useState("")
  const [steps, setstep] = useState([])
  const [costmargin, setcostmargin] = useState()


  // const totalCost = () => {
  //   const matesum = []
  //   steps.map((step) => {
  //     step.materials.map((mats) => {
  //       const mat = mats.quantity * mats.costprice;
  //       matesum.push(mat)
  //       setcostprice(matesum.reduce((a, b) => a + b, 0))
  //     })
  //   })
  
  // } 

  //meta title
  const createProduct = () => {
    const payload = {
      "resource": [
        {
          "name": name,
          "description": description,
          "b2bsalesprice": b2bsalesprice,
          "usageType": usageType,
          "sku": sku,
          "vat": vat,
          "stock": stock,
          "salesprice": salesprice,
          "vatClass": vatClass,
          "unit": unit,
          "category": category,
          "requirements": JSON.stringify(steps)
        }
      ],
    }

    postProduct(payload).then(res => {
      if (res && res.error) {
        //console.log(res)
      }
      if (res && res.resource[0]) {
        //console.log(res.resource[0])
        props.history.push("/product-overview/"+res.resource[0].id)
      }
    })
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Tasks" breadcrumbItem="Create Product" />

          <Row>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Product</CardTitle>
                  <form className="outer-repeater">
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Name
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            SKU
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="text"
                              className="form-control"
                              value={sku}
                              onChange={(e) => setsku(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                           UsageType
                          </label>
                          <div className="col-lg-10">
                            <Input value={usageType} onChange={(e) => setusageType(e.target.value)} type="select" name="select" id="exampleSelect">
                              <option value={"MATERIAL"}>MATERIAL</option>
                              <option value={"PURCHASED-MATERIAL"}>PURCHASED MATERIAL</option>
                              <option value={"COMPLETE-PRODUCT"}>COMPLETE PRODUCT</option>
                          

                            </Input>

                          </div>
                        </FormGroup>
                        

                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="taskname"
                            className="col-form-label col-lg-2"
                          >
                            Description
                          </Label>
                          <Col lg="10">
                            <Input
                              id="taskname"
                              name="taskname"
                              type="area"
                              className="form-control"
                              value={description}
                              onChange={(e) => setdescription(e.target.value)}
                              placeholder="Enter Task Name..."
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Category
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={category}
                              onChange={(e) => setcategory(e.target.value)}
                              placeholder="Enter Stock"
                              className="form-control"
                            />
                          </div>
                        </FormGroup>


                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Vat
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              onChange={(e) => setvat(e.target.value)}
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            VatClass
                          </label>
                          <div className="col-lg-10">
                            <Input
                              id="taskbudget"
                              name="taskbudget"
                              type="text"
                              value={vatClass}
                              onChange={(e) => setvatClass(e.target.value)}
                              placeholder="Enter Task Budget..."
                              className="form-control"
                            />
                          </div>
                        </FormGroup>

  

                        <FormGroup className="mb-4" row>
                          <label
                            htmlFor="taskbudget"
                            className="col-form-label col-lg-2"
                          >
                            Unit
                          </label>
                          <div className="col-lg-10">
                            <Input value={unit} onChange={(e) => setunit(e.target.value)} type="select" name="select" id="exampleSelect">
                              <option value={"KG"}>Killos</option>
                              <option value={"Meter"}>Meter</option>
                              <option value={"CM"}>Centimeters</option>
                              <option value={"MM"}>Milimmetes</option>
                              <option value={"Litter"}>Litter</option>
                              <option value={"CubicM"}>CubicM</option>
                              <option value={"Item"}>Item</option>
                              <option value={"Gram"}>Gram</option>

                            </Input>

                          </div>
                        </FormGroup>
                        
                      </div>
                    </div>
                  </form>
                  <Row className="justify-content-end">
                <Col lg="10">

                  <Button onClick={() => {

                    createProduct()
                  }} type="submit" color="primary">
                    Create product
                  </Button>
                </Col>
              </Row>
                </CardBody>
              </Card>
            </Col>
        
          </Row>


        </Container>

      </div>
    </>
  )
}
ProductCreate.propTypes = {
  history: PropTypes.object,
}

export default ProductCreate
