import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Input,
    Label

} from "reactstrap";
import { postTask } from "helpers/api_helper"
import { getTasksby } from "../../helpers/api_helper";
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom";
import SectionTable from "./sectionTable";
import { getProducts, patchTask } from "helpers/api_helper"
import { patchProduct, getTypes, getMaterials, postInvoice } from "../../helpers/api_helper"

import WebShot from "./webShot";
import { set } from "lodash";
import { check } from "prettier";

const SingleMulti = (props) => {
    //meta title
    document.title = "Tasks";
    const [selectedTask, setselectedTask] = useState({})
    const [tasks, setTasks] = useState([])
    const [selectedTaskMats, setselectedTaskMats] = useState([])
    const [taskPhotos, setTaskPhotos] = useState([])
    const [taskComments, setTaskComments] = useState([])
    const [isChecked, setIsChecked] = useState(true);
    const [totalQuantities, setTotalQuantities] = useState({});
    const [mergedTasks, setMergedTasks] = useState([]);
    const [showMerged, setShowMerged] = useState(false); // State to track if toggle is on
    const [summedMaterials, setSummedMaterials] = useState([]);

    useEffect(() => {
        setTaskComments("")
        setselectedTask({})
        setselectedTaskMats([])
        setTaskPhotos([])
        setTasks(props.sectiontasks)
        mergeTasksByProductName(props.sectiontasks); // Calculate total quantities
        sumMaterialsByName(mergedTasks);

    }, [props.sectiontasks])





    const sumMaterialsByName = (mergedTasks) => {
        const materialSummary = {};
    
        // Summing the quantities of materials
        mergedTasks.forEach(task => {
          task.materials.forEach(material => {
            const materialName = material.name;
            const materialQuantity = parseFloat(material.quantity) || 0;
    
            if (materialSummary[materialName]) {
              materialSummary[materialName].quantity += materialQuantity;
            } else {
              materialSummary[materialName] = {
                quantity: materialQuantity,
                productPhotos: material.productPhotos || [] // Store photos for later use
              };
            }
          });
        });
    
        // Convert the materialSummary object into an array of objects
        const summedMaterials = Object.keys(materialSummary).map(name => ({
          name,
          quantity: materialSummary[name].quantity,
          productPhotos: materialSummary[name].productPhotos // Include photos in the summary
        }));
    
        // Sort the materials by name A-Z
        summedMaterials.sort((a, b) => a.name.localeCompare(b.name));
    
        // Set the sorted result in state
        setSummedMaterials(summedMaterials);
      };
    
    
    const mergeTasksByProductName = (tasks) => {
        const mergedTasks = [];

        tasks.forEach(task => {
            const productName = task.product?.name;
            if (!productName) return; // Skip if no product name

            let taskMaterials = [];
            // Parse materials if they're a JSON string, handle "RETURN" case
            if (task.materials === "RETURN") {
                taskMaterials = [];
            } else {
                taskMaterials = JSON.parse(task.materials || "[]");
            }

            // Find if the product already exists in the merged tasks
            let existingProduct = mergedTasks.find(t => t.product.name === productName);

            if (existingProduct) {
                // Sum the quantities for the product
                existingProduct.quantity += parseFloat(task.quantity) || 0;

                // Multiply each material's quantity by the total summed product quantity
                existingProduct.materials = existingProduct.materials.map((material) => {
                    const taskMaterial = taskMaterials.find(mat => mat.id === material.id);
                    if (taskMaterial) {
                        // Multiply the task material's quantity by the product's total quantity
                        return {
                            ...material,
                            quantity: parseFloat(taskMaterial.quantity) * existingProduct.quantity
                        };
                    } else {
                        return material;
                    }
                });
            } else {
                // If this product doesn't exist, create a new entry
                mergedTasks.push({
                    ...task,
                    quantity: parseFloat(task.quantity) || 0, // Initial product quantity
                    materials: taskMaterials.map(material => ({
                        ...material,
                        quantity: parseFloat(material.quantity) * parseFloat(task.quantity) // Multiply material quantity by task quantity
                    }))
                });
            }
        });

        setMergedTasks(mergedTasks); // Set the merged tasks in state or handle them further
    };
    const createInvoice = async (i, mat) => {
        // props.setopenDrawer(true)

        let newArr = [...selectedTaskMats];
        newArr[i].stockOrdered = true;
        setselectedTaskMats(newArr);

        let newArr2 = { ...selectedTask };
        newArr2.materials = JSON.stringify(newArr)
        setselectedTask(newArr2)
        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => {
                if (task.id === newArr2.id) {
                    return newArr2;
                } else {
                    return task;
                }
            });
            //   props.setselctionTasks(updatedTasks)
            return updatedTasks;
        });

        let newArr3 = { ...mat };
        newArr3.quantity = selectedTask.quantity * parseFloat(mat.quantity);
        // console.log(newArr3)

        const payload = {
            "resource": [
                {
                    "providerid": 9999,
                    "importDate": new Date(),
                    "order_ref": selectedTask.orderref,
                    "section": selectedTask.type,
                    "type": "REQUEST-PM",
                    "content": JSON.stringify([newArr3]),
                }
            ],
        }

        postInvoice(payload).then(async (res) => { // add async here
            if (res && res.resource[0]) {

                let newArr = [...selectedTaskMats];
                newArr[i].stockOrdered = true;
                setselectedTaskMats(newArr);

                let newArr2 = { ...selectedTask };
                newArr2.materials = JSON.stringify(newArr)
                setselectedTask(newArr2)
                setTasks(prevTasks => {
                    const updatedTasks = prevTasks.map(task => {
                        if (task.id === newArr2.id) {
                            return newArr2;
                        } else {
                            return task;
                        }
                    });
                    //  props.setselctionTasks(updatedTasks)
                    return updatedTasks;
                });
                await updateTask(selectedTask.id, newArr, newArr2);
            }
        });
    }
    const finish = () => {
        const payload = {
            "resource": [
                {
                    "id": selectedTask.id,
                    // "materials": JSON.stringify(selectedTaskMats),
                    "photos": JSON.stringify(taskPhotos),
                    "status": "FINISHED"
                }
            ],
        }
        patchTask(payload).then(res => {
            if (res && res.error) {
                //console.log(res)
            }
            if (res && res.resource[0]) {
                // getTasksby(selectedTask.orderref).then(res => {
                // const finished = res.every(obj => obj.status === 'FINISHED')
                // const notTaskProduct = res.every(obj => obj.productid != selectedTask.productid)
                // if (finished || selectedTask.product.usageType === 'MATERIAL') {
                const prdstock = getLStock(selectedTask.productid).then(stock => {
                    const payload1 = {
                        "resource": [
                            {
                                "id": selectedTask.productid,
                                "stock": String(parseFloat(stock) + selectedTask.quantity), // Convert to string using String() function
                            }
                        ],
                    };
                    patchProduct(payload1).then(res => {
                        if (res && res.error) {
                            //console.log(res)
                        }

                        if (res && res.resource[0]) {
                            console.log(" STOCK UADTED TO PRODUCT ")
                            props.refreshTasks()

                        }
                    })
                });

            }
        })
    }
    const getLStock = async (id, i) => {
        const stock = await getProducts(id).then(res => res[0])
        return stock.stock
    }
    function checkStockReceived(payload) {

        return payload.every(item => item.stockReceived);
    }
    const updateTask = async () => {
        const payload = {
            "resource": [
                {
                    "id": selectedTask.id,
                    "materials": JSON.stringify(selectedTaskMats),
                    "comments": taskComments,

                }
            ]
        };
        try {
            const res = await patchTask(payload);
            if (res && res.error) {
                console.log(res);
            }
            if (res && res.resource[0]) {
                console.log("TaskUipdated")
            }
        } catch (error) {
            console.log(error);
        }
    }
    const updateTaskStatus = async () => {
        let newArr2 = { ...selectedTask };
        newArr2.status = "STOCK-RECEIVED"
        setselectedTask(newArr2)
        setTasks(prevTasks => {
            const updatedTasks = prevTasks.map(task => {
                if (task.id === newArr2.id) {
                    return newArr2;
                } else {
                    return task;
                }
            });

            return updatedTasks;
        });

        const payload = {
            "resource": [
                {
                    "id": selectedTask.id,
                    "materials": JSON.stringify(selectedTaskMats),
                    "comments": taskComments,
                    "status": "STOCK-RECEIVED"
                }
            ]
        };

        try {
            const res = await patchTask(payload);
            if (res && res.error) {
                console.log(res);
            }
            if (res && res.resource[0]) {
                console.log("DONE")
            }
        } catch (error) {
            console.log(error);
        }
    }
    const replaceMat = async (i) => {

        if (props.openDrawer == false && props.selectedReplace.new.id) {
            let newArr = [...selectedTaskMats];
            newArr[i] = props.selectedReplace.new;
            newArr[i].quantity = selectedTaskMats[i].quantity
            setselectedTaskMats(newArr);
            let newArr2 = { ...selectedTask };
            newArr2.materials = JSON.stringify(newArr)
            //console.log(newArr)
            setselectedTask(newArr2)

            setTasks(prevTasks => {
                const updatedTasks = prevTasks.map(task => {
                    if (task.id === newArr2.id) {
                        return newArr2;
                    } else {
                        return task;
                    }
                });
                //   props.setsectionTasks(updatedTasks)
                return updatedTasks;
            });
            //    await updateTask(selectedTask.id, newArr, newArr2);
        }

    }
    const gotStock = async (id, minustock, i, taskid) => {
        const payload = {
            "resource": [{
                "id": id,
                "stock": parseFloat(minustock).toFixed(4),
            }],
        }
        try {
            const res = await patchProduct(payload);

            if (res && res.error) {
                //console.log(res)
            }
            if (res && res.resource[0]) {
                let newArr = [...selectedTaskMats];
                newArr[i].stockReceived = true;
                newArr[i].pendingstock = false;
                setselectedTaskMats(newArr);
                let newArr2 = { ...selectedTask };
                newArr2.materials = JSON.stringify(newArr)
                setselectedTask(newArr2)
                setTasks(prevTasks => {
                    const updatedTasks = prevTasks.map(task => {
                        if (task.id === newArr2.id) {
                            return newArr2;
                        } else {
                            return task;
                        }
                    });

                    return updatedTasks;
                });

                await updateTask(taskid, newArr, newArr2);
            }
        } catch (error) {
            //console.log(error);
        }
    }
    const updateCost = async (set) => {
        if (!set || set.length === 0) {
            return;
        }
        const newSelectedTaskMats = [];
        // Use Promise.all to wait for all the getLStock calls to complete
        await Promise.all(
            set.map(async (mats) => {
                const res = await getLStock(mats.id);
                const newMats = { ...mats, stock: res };
                newSelectedTaskMats.push(newMats);
            })
        );
        setselectedTaskMats(newSelectedTaskMats);
    };
    const createTask = async (quantity, stk, task) => {
        const reqs = JSON.parse(stk.requirements);
        const results = [];
        for (const [i, tasks] of reqs.entries()) {
            const payload = {
                "resource": [
                    {
                        "type": tasks.typid,
                        "materials": JSON.stringify(tasks.materials),
                        "status": "PENDING",
                        "orderref": task.orderref,
                        "taskref": task.id,
                        "productid": stk.id,
                        "name": stk.name,
                        "description": "INTERNAL-TASK",
                        "quantity": parseFloat(quantity).toFixed(4),
                        "expectedDate": task.expectedDate,
                        "customer_id_ref": task.customer_id_ref,
                        "seq": task.seq
                    }
                ],
            }

            const res = await postTask(payload);
            if (res && res.error) {
                // handle error
            }
            if (res && res.resource) {
                results.push(res.resource);
                if (res.resource && res.resource[0]) {
                    console.log("Tasks created")
                    props.refreshTasks()
                    //  props.history.push("/tasks")
                }
            }
        }
        return results;
    }
    const checkOpenTasks = (selected, mat) => {
        const allT = props.allTasks
        const makis = allT.filter(task => parseFloat(task.taskref) === parseFloat(selectedTask.id) && task.orderref === selected.orderref && task.productid === mat.id && task.status != "FINISHED");
        console.log(makis)
        return makis
    }
    const handleOnChange = () => {
        isChecked ? setIsChecked(false) : setIsChecked(true)

    }
    const handleToggle = () => {
        setShowMerged(!showMerged);
    };


    return (
        <React.Fragment>

            <Button color="primary" onClick={handleToggle}>
                {showMerged ? "Close Sum" : "Summury View"}
            </Button>

            <Button color="primary" onClick={() => props.history.push("/logout")}>
                logout
            </Button>


            {/* Display totals of merged tasks */}
            <Row style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <Col>
                    {showMerged && (
                        <Card style={{ marginBottom: "20px" }}>
                            <CardBody>
                                <h5>Total Tasks Products and materials </h5>
                                {mergedTasks.map((task, index) => (
                                    <div key={index} style={{ marginBottom: "15px" }}>
                                  
                                            {task.product.name} Quantity: <strong>{task.quantity}</strong>
                                   
                                            <img
                                                src={JSON.parse(task.product.productPhotos)[0]} // Display the first image
                                    
                                                style={{marginLeft:10, width: '50px', height: '50px', marginRight: '10px' }}
                                            />
                                   
                                       
                              
                          
                        
                                        <div style={{ marginLeft: "20px" }}>
                                  
                                            {task.materials.map((material, matIndex) => (
                                                <Row key={matIndex}>
                                                    <Col>
                                                        <strong>{material.name}:</strong> {material.quantity} {material.unit}
                                                    </Col>
                                                </Row>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    )}

                </Col>


                <Col>
                    {summedMaterials && showMerged && (
                        <Card style={{ marginBottom: "20px" }}>
                            <CardBody>
                                <h5>Total Tasks SUM Materials</h5>
                                {summedMaterials.map((mat, index) => (
                                    <div key={index} style={{ marginBottom: "15px" }}>
                                          {mat.name} - Quantity: <strong>{mat.quantity}</strong> 
                                        
                                        {mat.productPhotos.length > 0 ? (
                                            <img
                                                src={JSON.parse(mat.productPhotos)[0]} // Display the first image
                                                alt={mat.name}
                                                style={{marginLeft:10, width: '50px', height: '50px', marginRight: '10px' }}
                                            />
                                        ) : (
                                            <div style={{ width: '50px', height: '50px', marginRight: '10px', backgroundColor: '#ddd', display: 'inline-block' }} />
                                        )}
                                      
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>


            <Card>
                <CardBody>
                    {/* Container for the SectionTable with max-height and scroll */}
                    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        <SectionTable
                            clickedItem={(tsk) => {
                                setselectedTaskMats([])
                                setselectedTask(tsk)
                                updateCost(JSON.parse(tsk.materials))
                            }}
                            tasks={tasks}
                        >
                        </SectionTable>
                    </div>
                </CardBody>
            </Card>



            {selectedTask && selectedTaskMats && selectedTask.status === "FINISHED" &&
                <div>
                    <Card>
                        <CardBody> Task has been executed  {selectedTask.updated}</CardBody>
                        <CardBody>  {selectedTask.product.name}</CardBody>
                        {selectedTask.photos && JSON.parse(selectedTask.photos).map((img, i) => (
                            <img key={i} src={img} alt="webcam preview" style={{ margin: 10, width: '150px', height: '150px' }} />
                        ))}
                        <CardBody>  Quantity :{selectedTask.quantity} </CardBody>
                    </Card>
                </div>
            }

            {selectedTask && selectedTaskMats && selectedTask.status !== "FINISHED" &&
                <div>
                    <Card>
                        {selectedTask.status === "PENDING" && <Row>
                            <Col>
                                <Label check>
                                    <Input type="radio" checked={isChecked} onChange={handleOnChange} />
                                    {' '}
                                    Normal Mode
                                </Label>
                            </Col>
                            <Col>

                                <Label>
                                    <Input type="radio" checked={!isChecked} onChange={handleOnChange} />
                                    {' '}
                                    Overide Mode
                                </Label>
                            </Col>
                            <Col>
                                {checkStockReceived(selectedTaskMats) && <Button onClick={() => updateTaskStatus()}>Proceed</Button>}
                            </Col>
                        </Row>
                        }
                        <CardBody>


                            {selectedTask.status === "PENDING" && <h5>Materials you need to Execute -- Get from Stock and Accept </h5>}

                            {selectedTask.status === "STOCK-RECEIVED" && <h5>Materials on your Section  -Please Execute the task  -- Last Update :{selectedTask.updated}</h5>}

                            {selectedTaskMats.map((mat, i) => (
                                <Row key={i} style={{ margin: 5, border: "2px solid black", justifyContent: "center", textAlign: "left", backgroundColor: "#D1E0FA" }} >
                                    <Col style={{ paddingTop: 12 }} lg="4">
                                    <Row>
                                    {mat.name}
                                        <img
                                                src={JSON.parse(mat.productPhotos)[0]} // Display the first image
                                    
                                                style={{marginLeft:10, width: '50px', height: '50px', marginRight: '10px' }}
                                            />
                                    </Row>
                                     
                                    </Col>


                                    <Col lg="6" >
                                        {!mat.stockReceived &&
                                            <Row
                                                style={{
                                                    paddingTop: 12,
                                                    justifyContent: "center",
                                                    textAlign: "left",
                                                    color: parseFloat(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) <= 0 ? "green" : "red"
                                                }}
                                            >
                                                Needs: {selectedTask.quantity} X {parseFloat(mat.quantity).toFixed(2)} {mat.unit} = {parseFloat(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity)).toFixed(2)} /{mat.unit} -- Live Stock: {mat.stock}
                                            </Row>
                                        }
                                        {mat.stockReceived && <Row>
                                            <div style={{ color: "green", paddingTop: 12, justifyContent: "center", textAlign: "left", }}>Stock On section</div>
                                        </Row>
                                        }



                                    </Col>
                                    <Col>


                                        {(parseFloat(selectedTask.quantity).toFixed(4) * parseFloat(mat.quantity).toFixed(4) - parseFloat(mat.stock).toFixed(4)) > 0.0001 && selectedTask.status === "PENDING" && !mat.stockReceived &&
                                            <Row>

                                                {checkOpenTasks(selectedTask, mat).length < 1 &&
                                                    <Col>
                                                        <div>
                                                            {mat.usageType === "PURCHASED-MATERIAL" && <div> PURCHASED-MATERIAL {mat.name} IS  OUT OF STOCK </div>}

                                                            {mat.usageType === "MATERIAL" && <Button style={{ margin: 10 }} onClick={() => createTask(parseFloat(selectedTask.quantity * parseFloat(mat.quantity)).toFixed(4), mat, selectedTask)}>CREATE TASK FOR {mat.name}</Button>}
                                                        </div>
                                                    </Col>
                                                }

                                                {checkOpenTasks(selectedTask, mat).length > 0 &&
                                                    <div>
                                                        PENDING TASKS ON PRODCTION ABOUT THIS MATERIAL
                                                        {checkOpenTasks(selectedTask, mat).map((tska, i) => (
                                                            <div key={i} >
                                                                Status : {tska.status} : {tska.quantity} of {tska.name} -- Order:{tska.orderref}</div>
                                                        ))}
                                                    </div>}


                                            </Row>
                                        }

                                        {selectedTask.status === "PENDING" &&
                                            <Row>
                                                {!mat.stockReceived &&
                                                    <div style={{ margin: 10 }}>


                                                        {!isChecked && mat.overidable &&
                                                            <div>


                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) <= 0.001 &&
                                                                    <Button onClick={() => gotStock(mat.id, (parseFloat(mat.stock) - parseFloat(selectedTask.quantity) * parseFloat(mat.quantity)), i, selectedTask.id)}>Stock Received</Button>
                                                                }
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) > 0.001 && !mat.stockOrdered && mat.usageType === "PURCHASED-MATERIAL" &&
                                                                    <Button onClick={() => createInvoice(i, mat)}>CREATE PARAGELIA</Button>
                                                                }
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) >= 0.001 &&
                                                                    <Button onClick={() => {
                                                                        props.setopenDrawer(true, mat, (parseFloat(selectedTask.quantity) * parseFloat(mat.quantity)))
                                                                    }}>REPLACE</Button>
                                                                }
                                                                {props.selectedReplace && props.selectedReplace.new && props.selectedReplace.new.id && props.selectedReplace.toreplace.id === mat.id &&
                                                                    <Button onClick={() => replaceMat(i)}>REPLACE -WITH :{props.selectedReplace.new.name}</Button>
                                                                }
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) > 0 && mat.stockOrdered && mat.usageType === "PURCHASED-MATERIAL" &&
                                                                    <Row>Stock has been Ordered</Row>
                                                                }
                                                            </div>
                                                        }

                                                        {isChecked &&
                                                            <div>
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) <= 0 &&
                                                                    <Button onClick={() => gotStock(mat.id, mat.stock - (parseFloat(selectedTask.quantity) * parseFloat(mat.quantity)), i, selectedTask.id)}>
                                                                        Stock received
                                                                    </Button>
                                                                }
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) > 0 && !mat.stockOrdered && mat.usageType === "PURCHASED-MATERIAL" &&
                                                                    <Button onClick={() => createInvoice(i, mat)}>
                                                                        CREATE PARAGELIA
                                                                    </Button>
                                                                }
                                                                {(parseFloat(selectedTask.quantity) * parseFloat(mat.quantity) - mat.stock) > 0 && mat.stockOrdered && mat.usageType === "PURCHASED-MATERIAL" &&
                                                                    <Row>
                                                                        Stock has been Ordered
                                                                    </Row>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                }
                                            </Row>
                                        }
                                    </Col>
                                    <Row>
                                    </Row>
                                </Row>
                            ))}

                            {selectedTask.status === "PENDING" || selectedTask.status === "STOCK-RECEIVED" &&
                                <Card>
                                    <Label>Comments</Label>
                                    <Input style={{ margin: 10 }} type='textarea' value={taskComments ? taskComments : ""} onChange={(e) => { setTaskComments(e.target.value) }} ></Input>
                                    <WebShot taskPhotos={taskPhotos} setTaskPhotos={setTaskPhotos} ></WebShot>

                                    <CardBody>
                                        <div>{selectedTask.id}--{selectedTask.name}  --- Quantity : {selectedTask.quantity}</div>
                                        {<Button onClick={() => finish()} style={{ backgroundColor: "red", margin: 10, minHeight: 200, minWidth: 200 }}>Finish</Button>}
                                    </CardBody>
                                </Card>
                            }


                        </CardBody>
                    </Card>
                </div>
            }
        </React.Fragment>
    );
};
SingleMulti.propTypes = {
    history: PropTypes.object,
    refreshTasks: PropTypes.func,
    setsectionTasks: PropTypes.func,
    sectiontasks: PropTypes.array,
    refresh: PropTypes.func,
    type: PropTypes.string,
    allTasks: PropTypes.any,
    setallTasks: PropTypes.any,
    openDrawer: PropTypes.any,
    setopenDrawer: PropTypes.any,
    selectedReplace: PropTypes.any


}
export default withRouter(SingleMulti);
