import React from 'react';
import { jsPDF } from "jspdf"; // Ensure you have jsPDF installed
  


export const processCustomerData = async (customer) => {
  if (!customer || !customer.orders || !customer.payments) {
    return null; // Ensure valid data is passed
  }

  // Filter orders with status "SHIPPED"
  const shippedOrders = customer.orders.filter(order => order.status === "SHIPPED");

  // Calculate total order value and order count for SHIPPED orders
  const { totalOrders, orderCount } = shippedOrders.reduce((acc, order) => {
    try {
      const parsedTotals = JSON.parse(order.totals);
      return {
        totalOrders: acc.totalOrders + (Number(parsedTotals.totalOrder) || 0),
        orderCount: acc.orderCount + 1
      };
    } catch (error) {
      console.error("Error parsing order totals:", error);
      return acc;
    }
  }, { totalOrders: 0, orderCount: 0 });

  // Calculate total payments received
  const totalPayments = customer.payments.reduce((acc, payment) => acc + (Number(payment.ammount) || 0), 0);

  // Find the last payment by sorting payments by date (handling milliseconds correctly)
  const lastPayment = customer.payments
    .filter(payment => payment.date) // Ensure date exists
    .sort((a, b) => new Date(b.date.replace(" ", "T")) - new Date(a.date.replace(" ", "T")))[0]; // Properly parse and sort

  return {
    id: customer.id,
    name: customer.name,
    totalOrders,
    totalPayments,
    orderCount,
    totalCustomer: totalOrders - totalPayments, // Remaining balance
    lastPaymentAmount: lastPayment ? Number(lastPayment.ammount) : 0, // Last payment amount
    lastPaymentDate: lastPayment ? lastPayment.date : null // Last payment date
  };
};



  export const calculateTotalQuantity = (data) => {
    return data.reduce((total, item) => {
      if (item.bound === 'INBOUND') {
        return total - item.quantity;
      } else if (item.bound === 'OUTBOUND') {
        return total + item.quantity;
      }
      return total; // In case bound is neither INBOUND nor OUTBOUND
    }, 0);
  };

  export const transformLoansData = (loans) => {
    const customerMap = {};
    loans.forEach((loan) => {
      const { customer_id, custname, prdname, quantity, bound } = loan;

      if (!customerMap[customer_id]) {
        customerMap[customer_id] = {
          customerid: customer_id,
          customername: custname,
          custloans: {},
        };
      }

      const customerLoans = customerMap[customer_id].custloans;

      if (!customerLoans[prdname]) {
        customerLoans[prdname] = 0;
      }

      // Adjust the total based on INBOUND or OUTBOUND
      if (bound === 'INBOUND') {
        customerLoans[prdname] -= quantity;
      } else if (bound === 'OUTBOUND') {
        customerLoans[prdname] += quantity;
      }
    });

    // Convert the customerMap into an array of customers with the desired structure
    return Object.values(customerMap).map((customer) => {
      const custloansArray = Object.keys(customer.custloans).map((productname) => ({
        productname,
        total: customer.custloans[productname],
      }));

      return {
        customerid: customer.customerid,
        customername: customer.customername,
        custloans: custloansArray,
      };
    });
  };



export const processInvoiceTotals = (invoices) => {
    return invoices.reduce(
        (result, invoice) => {
            const { refcode, totals } = invoice;

            // Parse the `totals` JSON string
            const parsedTotals = JSON.parse(totals);
            const totalPrdAmmount = parseFloat(parsedTotals.totalPrdAmmount || 0);
            const totalVat = parseFloat(parsedTotals.totalVat || 0);

            // Aggregate totals and fix to 2 decimal places
            result.totalPrdAmmount = parseFloat((result.totalPrdAmmount + totalPrdAmmount).toFixed(2));
            result.totalVat = parseFloat((result.totalVat + totalVat).toFixed(2));

            // Set Refcode
            result.Refcode = refcode;

            return result;
        },
        { Refcode: null, totalPrdAmmount: 0, totalVat: 0 } // Initial accumulator
    );
};
export const sendEmailOffer = async (order) => {
    try {
      const pdf = new jsPDF();

      // Generate the PDF content (this is where you'd customize your content)
      pdf.text('Invoice Details', 20, 20);
      pdf.text(`Order ID: ${order.id}`, 20, 30);
      pdf.text(`Customer Name: ${order.name}`, 20, 40);

      // Assuming order.products is an array of products
      const products = JSON.parse(order.products);
      let yPosition = 50; // Starting position for products
      products.forEach((product, index) => {
        pdf.text(`Product ${index + 1}: ${product.name}`, 20, yPosition);
        yPosition += 10;
        pdf.text(`Quantity: ${product.quantity}`, 20, yPosition);
        yPosition += 10;
        pdf.text(`Price: ${product.salesprice}`, 20, yPosition);
        yPosition += 20; // Extra space after each product
      });

      // Output the PDF and prepare the email
      const pdfOutput = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfOutput);
      const mailtoLink = `mailto:aspirtakis@gmail.com?subject=Invoice Offer&body=Please find the attached invoice.&attachment=${pdfUrl}`;

      // Open the mail client
      window.open(mailtoLink);
    } catch (error) {
      console.error("Error generating PDF or sending email:", error);
    }
  };