

//LOGIN
export const DFKEY = "b3718fea0e9d643640f58d581a0cb939873e90f102a434df8d60f1f9367e2da8"
//export const API_URL = "https://be.kerinoath.com/api/v2/"
//export const API_URL = "http://192.168.1.84:8888/api/v2/"
// export const API_URL = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '192.168.1.84' 
//   ? "http://192.168.1.84:8888/api/v2/"
//   : "https://be.kerinoath.com/api/v2/";


  export const API_URL = window.location.hostname === 'localhost' || 
                       window.location.hostname === '127.0.0.1' || 
                       window.location.hostname === '192.168.1.84' || 
                       window.location.hostname === '192.168.1.200' 
  ? (window.location.hostname === '192.168.1.200' ? "http://192.168.1.200:8888/api/v2/" : "http://192.168.1.84:8888/api/v2/")
  : "https://be.kerinoath.com/api/v2/";

export const KERINO = API_URL+"kerino/"
export const POST_LOGIN = API_URL+"user/session"
export const GET_TASKS = KERINO+"_table/tasks?filter=(status!=FINISHED)"
// export const GET_MATERIALS = KERINO+"_table/materials"
export const GET_TYPES = KERINO+"_table/taskTypes"
export const GET_BANKS = KERINO+"_table/banks"
export const GET_PROVIDERS = KERINO+"_table/providers"
export const INVOICES = KERINO+"_table/invoices"
export const CREATE_TASK = KERINO+"_table/tasks"
export const GET_ORDERS = KERINO+"_table/custorders"

export const GET_CUSTOMERS = KERINO+"_table/customers"
export const CREATE_CUSTOMER = KERINO+"_table/customers"
export const GET_PRODUCTS = KERINO+"_table/products"
export const SALESPERSONS = KERINO+"_table/salespersons"
export const CREATE_PRODUCT = KERINO+"_table/products"
export const CREATE_ORDER = KERINO+"_table/custorders"
export const CREATE_LOAN = KERINO+"_table/stockloans"
export const GET_CODES= KERINO+"_table/managecodes"
export const PAYMENTS = KERINO+"_table/payments"
export const SHIPPING = KERINO+"_table/shipments"