import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; 
import { Modal, ModalHeader, ModalBody, Row, Col,Label, Button, ListGroup, ListGroupItem, Card, CardBody, CardTitle, Input } from "reactstrap";
import CustomerSelect from "pages/Orders/customersmodal";
import { postTask, postLoan } from "helpers/api_helper";

const LoanModalCust = ({ fetchLoansData, isOpen, customers, setLoanModal,  loans, order = {} }) => {
  const [customerid, setCustomerid] = useState("");
  const [customername, setCustomername] = useState("");
  const [selectedCustomerLoans, setSelectedCustomerLoans] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [returnedQuantities, setReturnedQuantities] = useState({});

  useEffect(() => {
    if (isOpen) {
      setSelectedProducts([]);
      setReturnedQuantities({});
      setCustomerid("");
      setCustomername("");
      setSelectedCustomerLoans([]);

      if (order?.customer_id) {
        setCustomerid(order.customer_id);
        setCustomername(order.name);
        filterLoans(order.customer_id);
      }
    }
  }, [isOpen, order]);

  // Filter loans based on customer_id
  const filterLoans = (custId) => {
    const filteredLoans = loans
      .filter((loan) => loan.customer_id === custId)
      .reduce((acc, loan) => {
        const { order_id, prdname, prd_id, quantity, custname, bound, customer_id } = loan;
        const existingProduct = acc.find((item) => item.prd_id === prd_id);

        if (existingProduct) {
          if (bound === "OUTBOUND") {
            existingProduct.outbound += quantity;
          } else if (bound === "INBOUND") {
            existingProduct.inbound += quantity;
          }
          existingProduct.total = existingProduct.outbound - existingProduct.inbound;
        } else {
          acc.push({
            customer_id,
            order_id,
            prdname,
            custname,
            prd_id,
            inbound: bound === "INBOUND" ? quantity : 0,
            outbound: bound === "OUTBOUND" ? quantity : 0,
            total: bound === "OUTBOUND" ? quantity : -quantity,
          });
        }
        return acc;
      }, []);

    setSelectedCustomerLoans(filteredLoans);
  };

  // Handle manual customer selection
  const handleCustomerSelect = (selectedCustomerId) => {
    if (!selectedCustomerId) return;
    setCustomerid(selectedCustomerId);
    const selectedCustomer = customers.find((cust) => cust.id === selectedCustomerId);
    setCustomername(selectedCustomer ? selectedCustomer.name : "");
    filterLoans(selectedCustomerId);
  };

  // Handle product selection
  const handleProductSelect = (product) => {
    if (!selectedProducts.some((p) => p.prd_id === product.prd_id)) {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  // Handle quantity input
  const handleQuantityChange = (prd_id, quantity) => {
    setReturnedQuantities((prev) => ({ ...prev, [prd_id]: quantity }));
  };

  // Create returns for selected products
  const handleCreateReturns = async () => {
    for (const product of selectedProducts) {
      const returnedQuantity = returnedQuantities[product.prd_id];
      if (returnedQuantity && returnedQuantity > 0) {
        await createTaskAndLoan(product, returnedQuantity, customerid);
      }
    }
    setLoanModal(false);
  };

  // Create task and loan records
  const createTaskAndLoan = async (selectedProduct, returnedQuantity, customerid) => {
    const taskPayload = {
      resource: [
        {
          type: "ΠΛΥΝΤΗΡΙΟ",
          materials: "RETURN",
          status: "PENDING",
          orderref: selectedProduct.order_id,
          productid: selectedProduct.prd_id,
          name: selectedProduct.prdname,
          description: "EPISTROFH-PLYSIMO",
          quantity: parseInt(returnedQuantity),
          customer_id_ref: customerid,
          customer_name: selectedProduct.custname,
          seq: 1,
        },
      ],
    };

    try {
      const taskResponse = await postTask(taskPayload);
      if (taskResponse && taskResponse.resource) {
        const loanPayload = {
          resource: [
            {
              bound: "INBOUND",
              quantity: returnedQuantity,
              customer_id: customerid,
              order_id: selectedProduct.order_id,
              prd_id: selectedProduct.prd_id,
              prdname: selectedProduct.prdname,
              custname: selectedProduct.custname,
            },
          ],
        };
        await postLoan(loanPayload);
      }
    } catch (error) {
      console.error("Error in task or loan creation:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClosed={fetchLoansData}>
      <ModalHeader>Δημιουργία Τιμολογίου Επιστροφής Εισερχόμενου</ModalHeader>
      <ModalBody>
        <Row>
          {customername ? (
            <Col>Πελάτης: {customername}</Col>
          ) : (
            <Col>
              <Label>Επιλέξτε Πελάτη</Label>
              <CustomerSelect customers={customers} onSelect={handleCustomerSelect} />
            </Col>
          )}
        </Row>

        {selectedCustomerLoans.length > 0 && (
          <Row>
            <Col>
              <h5>Δανεισμένα Στοιχεία Πελάτη</h5>
              <ListGroup>
                {selectedCustomerLoans.map((loan) => (
                  <ListGroupItem key={loan.prd_id} onClick={() => handleProductSelect(loan)}>
                    <Row>{loan.prdname} Δανεισμένα: {loan.total}</Row>
                    <Row>
                      ΣΥΝΟΛΟ ΕΙΣΕΡΧΟΜΕΝΟ: {loan.inbound} ΣΥΝΟΛΟ ΕΞΕΡΧΟΜΕΝΟ: {loan.outbound}
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}

        {selectedProducts.length > 0 && (
          <Row>
            <Col>
              {selectedProducts.map((product) => (
                <Card key={product.prd_id}>
                  <CardBody>
                    <CardTitle>{product.prdname}</CardTitle>
                    <Input
                      type="number"
                      placeholder="Εισάγετε ποσότητα επιστροφής"
                      value={returnedQuantities[product.prd_id] || ""}
                      onChange={(e) => handleQuantityChange(product.prd_id, e.target.value)}
                    />
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
        )}

        {selectedProducts.length > 0 && (
          <Row>
            <Col>
              <Button color="primary" onClick={handleCreateReturns}>
                Δημιουργία Επιστροφών
              </Button>
            </Col>
          </Row>
        )}
      </ModalBody>
      <Button onClick={() => setLoanModal(false)}>Κλείσιμο</Button>
    </Modal>
  );
};

LoanModalCust.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  customers: PropTypes.array.isRequired,
  setLoanModal: PropTypes.func.isRequired,

  loans: PropTypes.array.isRequired,
  fetchLoansData: PropTypes.func.isRequired,
  order: PropTypes.object,
};

export default LoanModalCust;
