// src/components/filter.
import React, { useMemo } from "react";
import PropTypes from 'prop-types';

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
 import TableContainer from '../../components/Common/TableContainer';

function DatatableTables(props) {

const data = props.products

    const columns = useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'id'
            },
            {
                Header: 'SKU',
                accessor: 'sku'
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'category',
                accessor: 'category',
            },
            {
                Header: 'VatClass',
                accessor: 'vatClass'
            },
            {
                Header: 'Cost Price',
                accessor: 'costprice'
            },
         
            {
                Header: 'Stock',
                accessor: 'stock'
            },
            {
                Header: 'salesprice',
                accessor: 'salesprice'
            },
            {
                Header: 'units',
                accessor: 'unit'
            },
    
            {
                Header: 'usageType',
                accessor: 'usageType'
            },
        ],
        []
    );


    document.title = "Data Tables | kerino - React Admin & Dashboard Template";

    return (


                <TableContainer
                    rowClick={"/product-overview"}
                    columns={columns}
                    data={data}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={150}
                    className="custom-header-css"
          
                    
                />
   

    );
}
DatatableTables.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    products: PropTypes.any

};


export default DatatableTables;