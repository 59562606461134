import React, { useEffect, useState } from "react";
import { Col, Container, Input, Row, Button, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getProducts, patchOrder } from "helpers/api_helper";
import OrdersTable from "./ordersTable";
import { useTranslation } from "react-i18next"; // Import useTranslation
import { getOrders,  fetchOrdersDate } from "helpers/api_helper";
import moment from "moment"; // Ensure moment.js is installed



const InvoicesList = (props) => {
  const { t } = useTranslation(); // Initialize translation function
  document.title = t("Invoice List") + " | customers - React Admin & Dashboard Template";
  const [filter, setFilter] = useState("PENDING-STOCK");
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true); // Loader state
  const [fromDate, setFromDate] = useState(moment().subtract(7, "days").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));


  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const toggleDateFilter = () => setIsDateFilterOpen(!isDateFilterOpen);

  useEffect(() => {
    getOrders(true)
      .then(res => {
        setInvoices(res)

        const shippedOrders = res.filter(ord => ord.status === "PENDING-STOCK");
        setOrders(shippedOrders);
    
  })
      .catch(error => {
        localStorage.removeItem("authUser");
        props.history.push("/login"); // Redirect to login on error
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, []);

  const onGetInvoices = () => {
    getOrders()
      .then(res => setInvoices(res))
      .catch(error => {
        localStorage.removeItem("authUser");
        props.history.push("/login"); // Redirect to login on error
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });

  }


  const checkAndProcessOrders = async () => {
    setProcessing(true);
    setFilter("All");
    onGetInvoices();

    const pendingStockOrders = invoices.filter(order => order.status === "PENDING-STOCK");
    const stockOkOrders = invoices.filter(order => order.status === "STOCK-OK");

    // Processing PENDING-STOCK orders
    for (let order of pendingStockOrders) {
      const products = JSON.parse(order.products);
      let allStockAvailable = true;

      for (let product of products) {
        try {
          const productDetail = await getProducts(product.id);
          const productDetails = productDetail[0];

          if (!productDetails.stock || productDetails.stock < parseFloat(product.quantity)) {
            allStockAvailable = false;
            break;
          }
        } catch (error) {
          console.error(t("Error fetching product details:"), error);
          allStockAvailable = false;
          break;
        }
      }

      if (allStockAvailable) {
        const payload1 = {
          resource: [{ id: order.id, status: "STOCK-OK" }],
        };
        try {
          await patchOrder(payload1);
          console.log(t("Order") + ` ${order.id} ` + t("updated to STOCK-OK"));
        } catch (error) {
          console.error(t("Error updating order") + ` ${order.id}:`, error);
        }
      } else {
        console.log(t("Order") + ` ${order.id} ` + t("cannot be updated due to insufficient stock."));
      }
    }

    // Processing STOCK-OK orders
    for (let order of stockOkOrders) {
      const products = JSON.parse(order.products);
      let allStockAvailable = true;

      for (let product of products) {
        try {
          const productDetail = await getProducts(product.id);
          const productDetails = productDetail[0];

          if (!productDetails.stock || productDetails.stock < parseFloat(product.quantity)) {
            allStockAvailable = false;
            break;
          }
        } catch (error) {
          console.error(t("Error fetching product details:"), error);
          allStockAvailable = false;
          break;
        }
      }

      if (!allStockAvailable) {
        const payload2 = {
          resource: [{ id: order.id, status: "PENDING-STOCK" }],
        };
        try {
          await patchOrder(payload2);
          console.log(t("Order") + ` ${order.id} ` + t("updated to PENDING-STOCK"));
        } catch (error) {
          console.error(t("Error updating order") + ` ${order.id}:`, error);
        }
      } else {
        console.log(t("Order") + ` ${order.id} ` + t("has sufficient stock."));
      }
    }

    onGetInvoices();
    setOrders(invoices);
    setFilter("STOCK-OK");
    setProcessing(false);
  };

  const searchDates = () => {
    fetchOrdersDate(fromDate, toDate).then(res => {
      setInvoices(res)

    })


  }

  // useEffect(() => {
  //   if (filter === "SHIPPED") {
  //     getOrders(true).then(res => setInvoices(res));
  //   } else {
  //     const inv = invoices.filter(ord => 
  //       filter === "All" ? ord.status !== "OFFER-DELETE" : ord.status === filter
  //     );
  //     setOrders(inv);
  //   }
  // }, [filter, invoices]);

  useEffect(() => {
    if (filter === "SHIPPED") {
      getOrders(false).then(res => {
        const shippedOrders = res.filter(ord => ord.status === "SHIPPED");
        setOrders(shippedOrders);
      });
    } else {
      const inv = invoices.filter(ord => 
        filter === "All" ? ord.status !== "OFFER-DELETE" : ord.status === filter
      );
      setOrders(inv);
    }
  }, [filter]);
  
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <div className="loader">{t("Loading...")}</div> // Loader while fetching invoices
          ) : (
            <>
              <Card>
                {/* Clickable Header to Expand/Collapse */}
                <CardHeader onClick={toggleDateFilter} style={{ cursor: "pointer", background: "#f8f9fa" }}>
                  <strong>{t("Date Search")}</strong>
                  <span style={{ float: "right" }}>{isDateFilterOpen ? "▲" : "▼"}</span>
                </CardHeader>

                {/* Collapsible Date Filter */}
                <Collapse isOpen={isDateFilterOpen}>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md="2">
                        <strong>{t("Filter by Date")}:</strong>
                      </Col>
                      <Col md="3">
                        <Input
                          type="date"
                          value={fromDate}
                          onChange={e => setFromDate(e.target.value)}
                          placeholder={t("From Date")}
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          type="date"
                          value={toDate}
                          onChange={e => setToDate(e.target.value)}
                          placeholder={t("To Date")}
                        />
                      </Col>
                      <Col md="2">
                        <Button color="danger" onClick={() => { setFromDate(""); setToDate(""); }}>
                          {t("Clear Dates")}
                        </Button>
                      </Col>
                      <Col md="2">
                        <Button color="success" onClick={() => searchDates()}>
                          {t("Search")}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
              <Row style={{ marginBottom: 20, textAlign: "center" }}>
                <Col lg="2">
                  <Button color="primary" onClick={() => setFilter("STOCK-OK")} style={{ marginRight: "10px" }}>
                    {t("Ready for Invoicing")}
                  </Button>
                </Col>

                <Col lg="1">
                  <Button color="secondary" onClick={() => setFilter("PENDING-STOCK")} style={{ marginRight: "10px" }}>
                    {t("Pending Stock")}
                  </Button>
                </Col>

                <Col lg="2">
                  <Button color="warning" onClick={() => setFilter("ON SHIPPING")} style={{ marginRight: "10px" }}>
                    {t("On Shipping")}
                  </Button>
                </Col>
                <Col lg="1">
                  <Button color="info" onClick={() => setFilter("INVOICED")} style={{ marginRight: "10px" }}>
                    {t("Invoiced")}
                  </Button>
                </Col>

              
                <Col lg="1">
                  <Button color="primary" onClick={() => setFilter("OFFER")} style={{ marginRight: "10px" }}>
                    {t("Offers")}
                  </Button>
                </Col>
                <Col lg="1">
                  <Button color="success" onClick={() => setFilter("SHIPPED")} style={{ marginRight: "10px" }}>
                    {t("Shipped")}
                  </Button>
                </Col>
                <Col lg="1">
                  <Button color="danger" onClick={() => setFilter("RETURNED")} style={{ marginRight: "10px" }}>
                    {t("Returns")}
                  </Button>
                </Col>
                <Col lg="1">
                  <Button color="dark" onClick={() => setFilter("All")} style={{ marginRight: "10px" }}>
                    {t("All")}
                  </Button>
                </Col>
              
                <Col lg="1">
                  <Button color="primary" onClick={checkAndProcessOrders} disabled={processing}>
                    {processing ? t("Processing...") : t("Process")}
                  </Button>
                </Col>
                <Col lg="1">
                  <Button color="dark" onClick={() => onGetInvoices()} style={{ marginRight: "10px" }}>
                    {t("R")}
                  </Button>
                </Col>
              </Row>

              <OrdersTable orders={orders} />
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  history: PropTypes.object.isRequired, // Required prop for history
};

// const mapStateToProps = state => {
//   return {
//     layout: state.Layout,
//     ...state,
//   };
// };

export default withRouter(InvoicesList);
