import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody ,ReactDrawer} from "reactstrap";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { getLoans } from "helpers/api_helper";
import ReactApexChart from "react-apexcharts"; // Import ReactApexChart
import CustomerDetails from "./customerDetails";
import ProductDetails from "./prdDetails";
import CustomerSelect from "../Orders/customersmodal";
import { useSelector, useDispatch } from "react-redux"
import LoanModal from "./loanreturnmodal";
import { postLoan } from "helpers/api_helper";
import LoanModaladd from "./addLoan";
import { transformLoansData,calculateTotalQuantity } from "helpers/generalfunctions";

const Loans = (props) => {
  //meta title
  document.title = "Στατιστικά Δανείων";

  const [loans, setLoans] = useState([]);
  const [loanmodal, setloanmodal] = useState(false);
  const [viewType, setViewType] = useState("totals"); // Initialize view type to "totals"
  const [customerss, setCustomerss] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // Function to fetch loans and transform data
  const fetchLoansData = () => {
    getLoans().then((res) => {
      setLoans(res);
      setCustomerss(transformLoansData(res));
    });
  };

  // Use useEffect to load data on component mount
  useEffect(() => {
    fetchLoansData();
  }, []);


  // Process the data to calculate the total quantity per customer

  const { customers } = useSelector(state => ({
    customers: state.customers.customers
  }))

  // Handle view type change
  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
  };



  const totalQuantity = calculateTotalQuantity(loans);

  return (
    <React.Fragment>
      <div className="page-content">

      <div>
   

  
      <LoanModaladd
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        customers={customers}
        postLoan={postLoan}
      />
    </div>
        <Container>
          <Row>
            <Col>

            <Button onClick={() => setloanmodal(true)}>Προσθήκη Εισερχόμενου Δανείου</Button>
            </Col>
            <Col>
            <Button color="primary" onClick={() => setModalOpen(true)}>Exerxomeno</Button>
            </Col>
           
           
          </Row>

          <LoanModal fetchLoansData={fetchLoansData} loans={loans} customers={customers} setLoanModal={setloanmodal} isOpen={loanmodal}>
          </LoanModal>

          <Row>
            <Col>
              <h4>Συνολικά Δάνεια: {totalQuantity}</h4>
            </Col>
          </Row>
          <Row>
            <ProductDetails loans={loans}></ProductDetails>
          </Row>

          <Row>
            <CustomerDetails loans={loans}></CustomerDetails>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};

Loans.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
  customers: PropTypes.array,
};

export default withRouter(Loans);
