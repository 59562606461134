import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from "prop-types"


import AWS from 'aws-sdk';

const bucketName = 'tasksphotos';

const downloadEncryptedImage = async (imageKey) => {
  const s3 = new AWS.S3({
    accessKeyId: 'AKIA2Z4LN5KU5MCLXIBX',
    secretAccessKey: '7Ys8h4UKlcsSg3W3uw/dM3la/CXr5bkhrrqpxwcX',
    region: 'eu-central-1', // Correct region for your bucket
  });

  const params = {
    Bucket: 'tasksphotos',
    Key: imageKey,
  };

  try {
    const data = await s3.headObject(params).promise();
    console.log(`Encryption type for ${imageKey}:`, data.ServerSideEncryption);
  } catch (error) {
    console.error('Error checking encryption type:', error);
  }
};


const downloadAllEncryptedImages = async () => {
  try {
    const s3 = new AWS.S3({
      accessKeyId: 'AKIA2Z4LN5KU5MCLXIBX',
      secretAccessKey: '7Ys8h4UKlcsSg3W3uw/dM3la/CXr5bkhrrqpxwcX',
      region: 'eu-central-1', // Correct region for your bucket

  });
    const listParams = { Bucket: 'tasksphotos' };
    const data = await s3.listObjectsV2(listParams).promise();

    for (const obj of data.Contents) {
      const getParams = { Bucket: 'tasksphotos', Key: obj.Key };

      try {
        const imageData = await s3.getObject(getParams).promise();
        const blob = new Blob([imageData.Body], { type: 'image/jpeg' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = obj.Key.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        console.log(`Downloaded: ${obj.Key}`);
      } catch (downloadError) {
        console.error(`Failed to download ${obj.Key}:`, downloadError);
      }
    }
  } catch (error) {
    console.error('Error listing images:', error);
  }
};


const saveImageToS3 = (image) => {
  return new Promise((resolve, reject) => {
    const s3 = new AWS.S3({
        accessKeyId: 'AKIA2Z4LN5KU5MCLXIBX',
        secretAccessKey: '7Ys8h4UKlcsSg3W3uw/dM3la/CXr5bkhrrqpxwcX',
        region: 'eu-central-1', // Correct region for your bucket

    });

    const params = {
        Bucket: 'tasksphotos',
        Key: `images/${Date.now()}.jpg`,
        ContentType: image.type,
        Body: image,
        ACL: 'public-read',

  
      };
  

    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Location);
      }
    });
  });
};


const ImageUploadModal = (props) => {
    const [modal, setModal] = useState(false);
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
  
    const toggle = () => {
      setModal(!modal);
      setImage(null);
      setPreviewUrl(null);
    };
  
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setImage(file);
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
    };
  
    const handleImageUpload = async () => {
      // Your S3 bucket upload logic goes here
      // After the image is saved to S3, you can get the URL and pass it to the parent component via a prop
      const imageUrl = await saveImageToS3(image);
     // console.log(imageUrl)
      props.onImageUpload(imageUrl);

      toggle();
    };
  
    return (
      <div>
        <Button color="primary" onClick={toggle}>Upload Image</Button>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Image Upload</ModalHeader>
          <ModalBody>
            {previewUrl && <img src={previewUrl} alt="Preview" style={{ width: '100%' }} />}
            <input type="file" accept="image/*" onChange={handleImageChange} />
          </ModalBody>
          <ModalFooter>
            {previewUrl && <Button color="warning" onClick={() => setPreviewUrl(null)}>Reset</Button>}
            <Button color="primary" onClick={handleImageUpload}>Save</Button>{' '}
            <Button color="primary" onClick={() => downloadEncryptedImage('images/1681379109438.jpg')}>Download</Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  
  ImageUploadModal.propTypes = {
    onImageUpload: PropTypes.func.isRequired,
  };
  
  export default ImageUploadModal;