import React, { useMemo } from "react";
import PropTypes from 'prop-types';

// Import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import TableContainer from '../../components/Common/TableContainer';
import { useTranslation } from "react-i18next"; // Import useTranslation

function OrdersTable(props) {
    const { t } = useTranslation(); // Initialize translation function

    // Parse the `Totals` field and add `totalOrder` to each order object
    const data = props.orders.map(order => {
        const parsedTotals = JSON.parse(order.totals || '{}');
        return {
            ...order,
            totalOrder: parsedTotals.totalOrder || 0, // Default to 0 if `totalOrder` is missing
            totalVat: parsedTotals.totalVat || 0,
            totalProductsAmmount: parsedTotals.totalProductsAmmount || 0,
            totalItems: parsedTotals.totalItems || 0
        };
    });

    const columns = useMemo(
        () => [
            {
                Header: t('Creation Date'), // Translated header
                accessor: 'created',
            },
            {
                Header: t('ID'), // Translated header
                accessor: 'id',
            },
            {
                Header: t('Name'), // Translated header
                accessor: 'name',
            },
            {
                Header: t('label'), // Translated header
                accessor: 'label',
            },
            {
                Header: t('Delivery Date'), // Translated header
                accessor: 'expected_delivery_date',
            },
            {
                Header: t('Status'), // Translated header
                accessor: 'status',
            },
            {
                Header: t('Total Items'), // Translated header for `totalItems`
                accessor: 'totalItems',
            },
            {
                Header: t('Total Products Amount'), // Translated header for `totalProductsAmmount`
                accessor: 'totalProductsAmmount',
            },
            {
                Header: t('VAT'), // Translated header for `totalVat`
                accessor: 'totalVat',
            },
            {
                Header: t('Total Order'), // Translated header for `totalOrder`
                accessor: 'totalOrder',
            },
            {
                Header: t('Invoicing Date'), // Translated header for `invoicedAt`
                accessor: 'invoicedAt',
            },
        ],
        [t]
    );

    document.title = t("Data Tables") + " | kerino - React Admin & Dashboard Template";

    return (
        <TableContainer
            rowClick={"/order-detail"}
            columns={columns}
            data={data}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={150}
            className="custom-header-css"
        />
    );
}

OrdersTable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
    orders: PropTypes.array.isRequired
};

export default OrdersTable;
