import React, { useState, useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
const TransactionsTable = ({ transactions }) => {
      const history = useHistory();
  const [sortedData, setSortedData] = useState([]);
  const [sortField, setSortField] = useState("date");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const sorted = [...transactions].sort((a, b) =>
      sortOrder === "asc" ? a[sortField] - b[sortField] : b[sortField] - a[sortField]
    );
    setSortedData(sorted);
  }, [transactions, sortField, sortOrder]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  return (
    <Card>
      <CardBody>
        <h4 className="card-title mb-4">Κινήσεις</h4>
        <div style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd" }}>
          <Table className="table-striped">
            <thead className="table-light">
              <tr>
                <th onClick={() => handleSort("date")} style={{ cursor: "pointer" }}>
                  Ημερομηνία {sortField === "date" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                </th>
                <th>
                    Description
                </th>
                <th onClick={() => handleSort("payment")} style={{ cursor: "pointer" }}>
                  Πληρωμή {sortField === "payment" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                </th>
                <th onClick={() => handleSort("sale")} style={{ cursor: "pointer" }}>
                  Παραγγελία {sortField === "sale" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                </th>
                <th onClick={() => handleSort("refcode")} style={{ cursor: "pointer" }}>
                  Αναφορά {sortField === "refcode" ? (sortOrder === "asc" ? "▲" : "▼") : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedData.length > 0 ? (
                sortedData.map((entry, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: entry.type === "Πληρωμή" ? "#ffdddd" : "#ddffdd" }}
                    onClick={() => {
                        if (entry.type != "Πληρωμή") {
                          history.push("/order-detail/" + entry.orderid)
                        }
                        if (entry.type == "Πληρωμή")
                          history.push("/payment-overview/" + entry.orderid)
                      }
                    }
                  >             
    
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.type}</td>
                    <td>{entry.type === "Πληρωμή" ? `${parseFloat(entry.amount).toFixed(2)}€` : "-"}</td>
                    <td>{entry.type === "Παραγγελία" ? `${parseFloat(entry.amount).toFixed(2)}€` : "-"}</td>
                    <td>{entry.refcode}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">Δεν υπάρχουν δεδομένα.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

TransactionsTable.propTypes = {
  transactions: PropTypes.array.isRequired,
};

export default TransactionsTable;
