import React, { useState, useEffect } from 'react';
import { Table, Button, FormGroup, Input, Row, Col, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { getCustomerDetail, getCustomers, patchOrder } from "helpers/api_helper";
import { printInvoice } from './PrintInvoice';
import { printDriver } from './printshippingDriver';
import OptimalRouteMap from './optimalroute';
import { useTranslation } from "react-i18next"; // Import useTranslation for translations
import { postShipping } from 'helpers/api_helper';
import moment from 'moment';
function ShippingTable({ orders, onUpdateSelectedOrders }) {
  const { t } = useTranslation(); // Initialize translation function

  const [routeDetails, setRouteDetails] = useState([]); // To store the route details
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [sortedOrders, setSortedOrders] = useState(orders); // Store sorted orders in state
  const [sharedDetails, setSharedDetails] = useState({
    driver: '',
    type: '',
    car: '',
  });
  const [extraInfo, setExtraInfo] = useState({});
  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
  const [addresses, setAddresses] = useState([]); // To store selected addresses
  const [loading, setLoading] = useState(true); // Loading state for spinner

  // Fetch customers data
  useEffect(() => {
    getCustomers()
      .then(res => {
        setCustomers(res);
        setLoading(false); // Stop loading when customers are fetched
      })
      .catch(err => {
        console.error("Error fetching customers:", err);
        setLoading(false); // Stop loading in case of error
      });
  }, []);

  useEffect(() => {
    setAddresses([]);
    const newAddresses = selectedOrders.map(orderId => {
      const order = orders.find(order => order.id === orderId);
      const customer = Customers.find(cust => cust.id === order.customer_id);
      return customer.address ? customer.address : "";
    });

    console.log("Addresses updated:", newAddresses); // Log the addresses to check their validity
    setAddresses(newAddresses); // Update the addresses state
  }, [selectedOrders, Customers]);

  // Update sorted orders when the `orders` prop changes
  useEffect(() => {
    setSortedOrders(orders);
  }, [orders]);

  // Handle changes to shared fields
  const handleSharedFieldChange = (event) => {
    const { name, value } = event.target;
    setSharedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle changes to extra info per order
  const handleExtraInfoChange = (orderId, value) => {
    setExtraInfo((prevInfo) => ({
      ...prevInfo,
      [orderId]: value,
    }));
  };

  // Toggle selection for orders
  const handleCheckboxChange = (orderId) => {
    setSelectedOrders((prevSelectedOrders) =>
      prevSelectedOrders.includes(orderId)
        ? prevSelectedOrders.filter((id) => id !== orderId)
        : [...prevSelectedOrders, orderId]
    );
  };

  // Pre-fill shared details when one or more orders are selected
  useEffect(() => {
    if (selectedOrders.length > 0) {
      const firstOrder = orders.find(order => order.id === selectedOrders[0]);
      if (firstOrder && firstOrder.shipping) {
        const shippingInfo = JSON.parse(firstOrder.shipping || '{}');
        setSharedDetails({
          driver: shippingInfo.driver || '',
          type: shippingInfo.type || '',
          car: shippingInfo.car || '',
        });
      }
    } else {
      setSharedDetails({ driver: '', type: '', car: '' });
    }
  }, [selectedOrders, orders]);



  // const updateOrders = async () => {
  //   const payload = selectedOrders.map(orderId => ({
  //     id: orderId,
  //     status: "ON SHIPPING",  // Status change
  //     shipping: JSON.stringify({
  //       ...sharedDetails,
  //       extra: extraInfo[orderId] || '',
  //     }),
  //     shipping_by: sharedDetails.driver,
  //   }));
  
  //   try {
  //     // Update orders
  //     const res = await Promise.all(payload.map(orderData => patchOrder({ resource: [orderData] })));
  
  //     if (res) {
  //       // After updating orders, create a new shipping entry with only order IDs
  //       const shippingPayload = {
  //         driver: sharedDetails.driver,
  //         status:"PENDING",
  //         orders: JSON.stringify(selectedOrders.map(id => ({ id }))), // Only order IDs
  //         route: JSON.stringify(addresses), // Route details as JSON string
  //       };
  //       await postShipping({ resource: [shippingPayload] });

  //       onUpdateSelectedOrders();
  //     }
  //   } catch (error) {
  //     console.error("Error updating orders:", error);
  //   }
  // };
  
  const updateOrders = async () => {
    const payload = selectedOrders.map(orderId => ({
      id: orderId,
      status: "ON SHIPPING", // Status change
      shipping: JSON.stringify({
        ...sharedDetails,
        extra: extraInfo[orderId] || '',
      }),
      shipping_by: sharedDetails.driver,
    }));
  
    try {
      // Update orders
      await Promise.all(payload.map(orderData => patchOrder({ resource: [orderData] })));
  
      // Create a new shipping entry after all orders are updated
      const shippingPayload = {
        driver: sharedDetails.driver,
        status: "PENDING",
        orders: JSON.stringify(selectedOrders.map(id => ({ id }))), // Only order IDs
        route: JSON.stringify(addresses), // Route details as JSON string
      };
  
      const shippingResponse = await postShipping({ resource: [shippingPayload] });
  
      if (shippingResponse) {
        onUpdateSelectedOrders(); // Call only after postShipping completes
      }
    } catch (error) {
      console.error("Error updating orders:", error);
    }
  };
  
const printSelectedOrders = async () => {
  const selectedOverviewPromises = selectedOrders.map(async (orderId) => {
    const order = orders.find(order => order.id === orderId);
    const { totalOrder, totalItems } = JSON.parse(order.totals || '{}');

    const customer = await getCustomerDetail(order.customer_id);
    if (!customer || customer.length === 0) {
      console.error(`Customer not found for order ${orderId}`);
      return null;
    }

    const address = order.delivery_address || customer[0].address;

    return {
      orderId: order.id,
      name: order.name,
      label: customer[0].label || 'N/A', // Add customer label
      address,
      postcode: customer[0].postcode,
      phone: customer[0].phone,
      totalItems,
      totalOrder,
      invoicedAt: order.invoicedAt || 'N/A', // Add invoicedAt from order
      driver: sharedDetails.driver || 'N/A',
      extraInfo: extraInfo[orderId] || 'N/A',
    };
  });

  const selectedOverview = (await Promise.all(selectedOverviewPromises)).filter(Boolean);

  const routeOverview = routeDetails.map((leg) => ({
    from: leg.start,
    to: leg.end,
    distance: leg.distance,
    duration: leg.duration,
  }));

  const sortedSelectedOverview = selectedOverview.sort((a, b) => {
    const routeAIndex = routeOverview.findIndex(route => route.to === a.address);
    const routeBIndex = routeOverview.findIndex(route => route.to === b.address);
    return routeAIndex - routeBIndex;
  });

  const ordersTableRows = sortedSelectedOverview.map(overview => `
    <tr>
      <td>${overview.orderId}</td>
      <td>${overview.name}</td>
      <td>${overview.label}</td>
      <td>${overview.address}</td>
      <td>${overview.phone}</td>
      <td>${overview.totalItems}</td>
      <td>${overview.totalOrder}</td>
      <td>${overview.invoicedAt}</td>
      <td>${overview.extraInfo}</td>
    </tr>
  `).join('');

  const routeTableRows = routeOverview.map(route => `
    <tr>
      <td>${route.from}</td>
      <td>${route.to}</td>
      <td>${route.distance}</td>
      <td>${route.duration}</td>
    </tr>
  `).join('');

  const htmlContent = `
  <html>
    <head>
      <style>
        @page {
          size: landscape; /* Forces landscape orientation */
          margin: 1in;
        }
        body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
          font-size: 16px;
          text-align: left;
        }
        th, td {
          border: 1px solid #ddd;
          padding: 8px;
        }
        th {
          background-color: #f4f4f4;
        }
        tr:nth-child(even) {
          background-color: #f9f9f9;
        }
      </style>
    </head>
    <body>

      <table>
        <thead>
              <h2>${sharedDetails.driver}</h2>
          <tr>
            <th>${t('Order ID')}</th>
            <th>${t('Name')}</th>
            <th>${t('Label')}</th>
            <th>${t('Customer Address')}</th>
            <th>${t('Customer Phone')}</th>
            <th>${t('Total Items')}</th>
            <th>${t('Total Order')}</th>
            <th>${t('Invoiced At')}</th>
            <th>${t('Extra Info')}</th>
          </tr>
        </thead>
        <tbody>
          ${ordersTableRows}
        </tbody>
      </table>


      <table>
        <thead>
          <tr>
            <th>${t('From')}</th>
            <th>${t('To')}</th>
            <th>${t('Distance')}</th>
            <th>${t('Duration')}</th>
          </tr>
        </thead>
        <tbody>
          ${routeTableRows}
        </tbody>
      </table>
    </body>
  </html>
`;

  printDriver(htmlContent);
};


  return (
    <div>
      {loading &&
        <div className="text-center">
          <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
          <p>{t('Loading Customers')}...</p>
        </div>
      }
      {!loading &&
        <Row>
          <Col lg="8">
          <div style={{ width: '100%', maxHeight: '500px', overflowY: 'auto', borderRadius: '10px', border: '1px solid #ddd', background: '#ffffff', padding: '10px' }}>
  <Table striped hover responsive style={{ background: '#f9f9f9', borderRadius: '10px' }}>
    <thead>
      <tr style={{ backgroundColor: '#007bff', color: 'white', textAlign: 'left' }}>
        <th>{t('Order ID')}</th>
        <th>{t('ExpDelivery')}</th>
        <th>{t('Name')}</th>
        <th>{t('Titlos')}</th>
        <th>{t('Customer ID')}</th>
        <th>{t('Items')}</th>
        <th>{t('Total')}</th>
        <th>{t('Print')}</th>
      </tr>
    </thead>
    <tbody>
      {sortedOrders.map((order) => {
        const { totalOrder = 0, totalItems = 0 } = JSON.parse(order.totals || '{}');
        const customer = Customers.find(cust => cust.id === order.customer_id);
        const isSelected = selectedOrders.includes(order.id);

        return (
          <React.Fragment key={order.id}>
            {/* Main Row (Clickable) */}
            <tr
              onClick={() => handleCheckboxChange(order.id)}
              style={{
                cursor: 'pointer',
                backgroundColor: isSelected ? '#b4e784' : 'white',
                fontWeight: isSelected ? 'bold' : 'normal',
                color: isSelected ? 'blue' : 'black',
                transition: 'background 0.3s ease',
              }}
            >
              <td>{order.id}</td>
              <td>{moment(order.expected_delivery_date).format("DD/MM/YYYY")}</td>
              <td>{order.name}</td>
              <td>{customer?.label}</td>
              <td>{order.customer_id}</td>
              <td>{totalItems}</td>
              <td>{Number(totalOrder).toFixed(2)}</td>
              <td>
                <Button
                  color="link"
                  style={{ padding: 0, color: '#007bff', fontSize: '20px' }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click from triggering
                    printInvoice(order);
                  }}
                  title={t('Print Invoice')}
                >
                  <i className='bx bx-printer'></i>
                </Button>
              </td>
            </tr>

            {/* Expandable Row (Appears when selected) */}
            {isSelected && (
              <tr>
                <td colSpan="8" style={{ backgroundColor: '#b4e784', padding: '10px 20px' }}>
                  <FormGroup>
                    <Row>
                      <Col md="3">
                        <Input
                          type="select"
                          name="type"
                          value={sharedDetails.type}
                          onChange={handleSharedFieldChange}
                          className="form-select"
                        >
                          <option value="">{t('Select Type')}</option>
                          <option value="Driver">{t('Driver')}</option>
                          <option value="Courier">{t('Courier')}</option>
                          <option value="ACS">ACS</option>
                          <option value="DHL">DHL</option>
                        </Input>
                      </Col>
                      <Col md="3">
                        <Input
                          type="select"
                          name="driver"
                          value={sharedDetails.driver}
                          onChange={handleSharedFieldChange}
                          className="form-select"
                        >
                          <option value="">{t('Select Driver')}</option>
                          <option value="ΝΙΚΗΤΑΣ">ΝΙΚΗΤΑΣ</option>
                          <option value="ΓΙΩΡΓΟΣ">ΓΙΩΡΓΟΣ</option>
                          <option value="ΒΑΣΙΛΗΣ">ΒΑΣΙΛΗΣ</option>
                          <option value="ΑΛΕΞΗΣ">ΑΛΕΞΗΣ</option>
                          <option value="ΝΕΚΤΑΡΙΟΣ">ΝΕΚΤΑΡΙΟΣ</option>
                          <option value="EXTRA DRIVER">EXTRA DRIVER</option>
                        </Input>
                      </Col>
                      <Col md="3">
                        <Input
                          type="select"
                          name="car"
                          value={sharedDetails.car}
                          onChange={handleSharedFieldChange}
                          className="form-select"
                        >
                          <option value="TAXI">TAXI</option>
                          <option value="XHZ-6763">XHZ-6763</option>
                          <option value="XHZ-6762">XHZ-6762</option>
                          <option value="ITM-3571">ITM-3571</option>
                          <option value="IMH-2647">IMH-2647</option>
                          <option value="BKX-8246">BKX-8246</option>
                        </Input>
                      </Col>
                      <Col md="3">
                        <Input
                          type="text"
                          placeholder={t('Extra Information')}
                          value={extraInfo[order.id] || ''}
                          onChange={(e) => handleExtraInfoChange(order.id, e.target.value)}
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  </Table>
</div>


          </Col>
          <Col lg="4">
            {selectedOrders.length > 0 && (
              <div>
                <Button color="secondary" onClick={printSelectedOrders} style={{ marginRight: '10px' }}>
                  {t('Print Driver Plan')}
                </Button>
                <Button color="primary" onClick={updateOrders}>
                  {t('Send Selected Orders')}
                </Button>
              </div>
            )}
            {selectedOrders.length > 0 && 
              <OptimalRouteMap addresses={addresses} onRouteCalculated={setRouteDetails} />
            }
          </Col>
        </Row>
      }
    </div>
  );
}

ShippingTable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      totals: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      shipping: PropTypes.string,
      delivery_address: PropTypes.string,
      customer_id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onUpdateSelectedOrders: PropTypes.func.isRequired,
};

export default ShippingTable;
