import React, { useState,useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from "prop-types";


const ShipModal = (props) => {
  const [modal, setModal] = useState(props.modalClose);

  const toggle = () => setModal(!modal);
  
  useEffect(() => {
     setModal(props.modalClose)
  }, [props.modalClose])


  return (
    <div>
  
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Confirm Shipping</ModalHeader>
        <ModalBody>
        {props.customer?.customfields
                              ? JSON.parse(props.customer.customfields).map((field, index) => (
                                <div key={index}>
                                  <strong>{field.name}:</strong> {field.value}
                                </div>
                              ))
                              : <p>No custom fields available</p>}
        <Button color="secondary" onClick={() => props.sendToEpsilon()}>Epsilon</Button>{' '}
          <Button color="danger" onClick={() => props.sendToReds()}>Red</Button>{' '}
       
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() =>props.setModal()}>Cancel</Button>{' '}
        
        </ModalFooter>
      </Modal>
    </div>
  );
}

ShipModal.propTypes = {
    sendToEpsilon: PropTypes.func,
    sendToReds: PropTypes.func,
    modalClose:PropTypes.bool,
    setModal:PropTypes.func,
    customer:PropTypes.any

  
  }

export default ShipModal;