import React, { useEffect, useState } from "react";
import { Col, Row, Container, ListGroup, ListGroupItem, Card, CardBody, CardTitle, CardText, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getShipmentsDriver, getOrdersbyid } from "helpers/api_helper";
import OptimalRouteMap from "pages/Orders/optimalroute";
import LoanModalCust from "pages/loans/loanreturncust";
import { getLoans, getCustomers } from "helpers/api_helper";
import { transformLoansData } from "helpers/generalfunctions";
import { patchOrder, postShipping, patchShipment } from "helpers/api_helper";
import PaymentModal from "./paymentmodal";

import { useHistory } from "react-router-dom"; // Import useHistory


import LoanModal from "pages/loans/loanreturnmodal";
const ShipmentsPage = () => {
  const [shipments, setShipments] = useState([]);

  const [loans, setLoans] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [driver, setDriver] = useState("");
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [showmap, setshowMap] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [routeDetails, setRouteDetails] = useState([]);
  const [returnsModal, setReturnsModal] = useState(false);
  const [returnOrder, setReturnOrder] = useState(null); // Stores selected order for return
  const [customerss, setCustomerss] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [email, setEmail] = useState("");


  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const openPaymentModal = (customerId, customerName) => {
    setSelectedCustomer({ id: customerId, name: customerName });
    setIsPaymentModalOpen(true);
  };

  const history = useHistory(); // Initialize history object
 
  useEffect(() => {
    const user = localStorage.getItem("authUser");
    if (user) {

      try {
        const parsedUser = JSON.parse(user);
        console.log(parsedUser)
        setEmail(parsedUser.email || "");
        setDriver(parsedUser.name || "");
        if(parsedUser.name.length > 2 ) {
          fetchShipments(parsedUser.name);
        }

      } catch (error) {
        console.error("Error parsing authUser:", error);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("authUser"); // Remove user session
    history.push("/login"); // Redirect to login page
  };

  const fetchLoansData = () => {
    getLoans().then((res) => {
      setLoans(res);
      setCustomerss(transformLoansData(res));
      getCustomers().then(res => setCustomers(res))
    });
  };



  const fetchShipments = async (drv) => {
{
      const data = await getShipmentsDriver(drv);
      setShipments(data);
      setLoading(false);
      fetchLoansData();
    }

  };


  const handleSelectShipment = async (shipment) => {
    setSelectedShipment(shipment);
    setAddresses(JSON.parse(shipment.route));
    setSelectedOrders([]);
    setOrdersLoading(true);

    try {
      const orderIds = JSON.parse(shipment.orders).map(order => order.id);
      const orderDetails = await Promise.all(orderIds.map(id => getOrdersbyid(id)));

      // Filter only "ON SHIPPING" orders
      const filteredOrders = orderDetails.flat().filter(order => order.status === "ON SHIPPING");

      setSelectedOrders(filteredOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setOrdersLoading(false);
    }
  };
  const orders = selectedShipment && selectedShipment.orders ? JSON.parse(selectedShipment.orders) : {}

  const finalizeShipment = async () => {
    if (selectedShipment && selectedOrders.length === 0) {
      try {
        const payload1 = { resource: [{ id: selectedShipment.id, status: "DONE" }] };
        await patchShipment(payload1);
        fetchShipments(); // Refresh shipment data
      } catch (error) {
        console.error("Error updating shipment status:", error);
      }
    } else {
      alert("Shipment cannot be finalized, orders still exist.");
    }
  };



  const updateOrderStatus = async (orderId, status) => {
    try {
      const payload = {
        resource: [
          {
            id: orderId,
            status: status,
          }
        ],
      };

      const res = await patchOrder(payload);
      if (res && res.resource[0]) {
        // Remove the shipped order from the list
        setSelectedOrders(prevOrders => prevOrders.filter(order => order.id !== orderId));

      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };



  const markAsFailed = (orderId) => {
    console.log(`Order ${orderId} marked as FAILED`);
    // TODO: Add API call to update order status
  };

  const openReturnsModal = (order) => {
    setReturnOrder(order);
    setReturnsModal(true);
  };

  return (
    <Container >
      <h4 className="text-center">Driver: {driver}</h4>
      <Row>
        <Col>
          <Button

            size="sm"
            onClick={() => openReturnsModal({})}

          >
            RETURNS
          </Button>
        </Col>
        <Col className="text-end">
          <Button size="sm" color="success" onClick={handleLogout}>
            Logout
          </Button>
        </Col>
      </Row>





      {loading ? (
        <div className="text-center">
          <Spinner color="primary" />
        </div>
      ) : (
        <ListGroup style={{ maxHeight: "300px", overflowY: "auto" }}>
          {shipments.map((shipment) => (
            <ListGroupItem key={shipment.id} onClick={() => handleSelectShipment(shipment)} action>
              <strong>Route:</strong> {shipment.id} <strong>Updated:</strong> {shipment.update}
              <Button
                size="sm" style={{ marginLeft: 20 }} onClick={() => finalizeShipment()}>Finish</Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}

      {ordersLoading ? (
        <div className="text-center mt-3">
          <Spinner color="secondary" />
        </div>
      ) : selectedOrders.length > 0 && (
        <Card className="mt-3">
          <CardBody>
            <Button onClick={() => setshowMap(prev => !prev)}>Toggle Map</Button>
            {addresses.length > 0 &&
              <OptimalRouteMap Dontshow={showmap} addresses={addresses} onRouteCalculated={setRouteDetails} />
            }

            <CardTitle tag="h5">Orders in Shipment</CardTitle>
            <ListGroup>
              {selectedOrders.map(order => {
                // Find customer in customerss by matching customer_id
                const customerLoans = customerss.find(c => c.customerid === order.customer_id);
                // Calculate total loans for this customer
                const totalLoans = customerLoans
                  ? customerLoans.custloans.reduce((sum, loan) => sum + loan.total, 0)
                  : 0;
                return (
                  <Row style={{ marginTop: 5, backgroundColor: order.invoicedAt != "epsilon" ? "#f9b6ac" : "#8dc69c" }} key={order.id} >

                    <Row>
                      <ListGroupItem style={{ margin: 4 }} className="d-flex  align-items-center">
                        <div>
                          <strong>Destination:</strong> {JSON.parse(order.delivery_address).address} <br />
                          <strong>Customer:</strong> {order.name.length > 20 ? `${order.name.substring(0, 40)}...` : order.name}
                          <br />
                          {order.label.length > 2 && <div> <strong>Label:</strong> {order.label}  <br /></div>}

                          <strong>Status:</strong>
                          <span className={`badge ${order.status === "SHIPPED" ? "bg-success" :
                            order.status === "INVOICED" ? "bg-warning text-dark" :
                              order.status === "RETURNED" ? "bg-danger" :
                                "bg-secondary"
                            } ms-2`}>
                            {order.status}
                          </span> <br />
                          <strong>Totals:</strong> Products: {JSON.parse(order.totals).totalItems} - TEuro: {Number(JSON.parse(order.totals).totalOrder).toFixed(2)} <br />
                        </div>
                        <div>


                        </div>
                      </ListGroupItem>
                    </Row>
                    <Row className="mb-2">
                      <Col xs="12">
                        <div className="d-flex flex-wrap gap-4 mt-2">
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => updateOrderStatus(order.id, "SHIPPED")}
                          >
                            DELIVERED
                          </Button>

                          <Button
                            color="secondary"
                            size="sm"
                            onClick={() => updateOrderStatus(order.id, "INVOICED")}
                          >
                            TO-PLANNING
                          </Button>


                          {totalLoans > 0 &&
                            <Button
                              color={totalLoans > 10 ? "danger" : "warning"}
                              size="sm"
                              onClick={() => openReturnsModal(order)}
                            >
                              LOANS {totalLoans > 0 && `(${totalLoans})`}
                            </Button>
                          }

                          <Button
                            color="success"
                            size="sm"
                            onClick={() => openPaymentModal(order.customer_id, order.name)}
                          >
                            PAYMENTS
                          </Button>

                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => updateOrderStatus(order.id, "RETURNED")}
                          >
                            RETURN
                          </Button>
                        </div>
                      </Col>
                    </Row>







                  </Row>

                );
              })}
            </ListGroup>

          </CardBody>
        </Card>
      )}
      {returnOrder && returnOrder.customer_id ? <LoanModalCust fetchLoansData={() => fetchLoansData()} loans={loans} customers={customers} setLoanModal={setReturnsModal} isOpen={returnsModal} order={returnOrder}>
      </LoanModalCust>
        : <LoanModal fetchLoansData={fetchLoansData} loans={loans} customers={customers} setLoanModal={setReturnsModal} isOpen={returnsModal}>
        </LoanModal>}

      {selectedCustomer && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          toggle={() => setIsPaymentModalOpen(false)}
          customerId={selectedCustomer.id}
          customerName={selectedCustomer.name}
        />
      )}


      {/* Returns Modal */}

    </Container>
  );
};

export default ShipmentsPage;
