import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalBody, ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from "prop-types"


import AWS from 'aws-sdk';

// Set your AWS credentials
AWS.config.update({
  accessKeyId: 'AKIA2Z4LN5KU5MCLXIBX',
  secretAccessKey: '7Ys8h4UKlcsSg3W3uw/dM3la/CXr5bkhrrqpxwcX',
  region: 'eu-central-1', // Correct region for your bucket
});

const ProductWebshot = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const videoRef = useRef(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (!modalOpen) {
      startPreview();
    }
  };
  const deleteImage = (img) => {
    setImages(images.filter(image => image !== img));
  };
  const startPreview = () => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(stream => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      });
  };

  const s3 = new AWS.S3();

  const takePicture = () => {
    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
  
    canvas.toBlob(blob => {
      const file = new File([blob], 'image.jpg', { type: blob.type });
      const params = {
        Bucket: 'tasksphotos',
        Key: `images/${Date.now()}.jpg`,
        ContentType: file.type,
        Body: file,
        ACL: 'public-read',
      };
  
      // Upload the file to S3
      s3.upload(params, (err, data) => {
        if (err) {
          console.error(err);
        } else {
          setImages([...images, data.Location]);
        }
      });
    });
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    video.onloadedmetadata = () => {
      video.play();
    };
  }, []);

  return (
    <div>
      <Button color="primary" onClick={toggleModal}>Take Picture</Button>
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalBody>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '60%' }}>
              <video ref={videoRef} style={{ width: '400px', height: '400px' }} />
              <Button color="primary" onClick={takePicture}>Take Picture</Button>
            </div>
            <ListGroup style={{ width: '40%', marginLeft: '10px' }}>
              {images.map((img, index) => (
                <ListGroupItem onDoubleClick={() => deleteImage(img)}  key={index} onClick={() => {
                  props.setTaskPhotos([...props.taskPhotos, img]);
            
                  toggleModal()}}>
                  <img src={img} alt="webcam preview" style={{ width: '150px', height: '150px' }} />
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </ModalBody>
      </Modal>
      <div>

  
    

      </div>
    </div>
  );
};

ProductWebshot.propTypes = {
  setTaskPhotos: PropTypes.func,
  taskPhotos:PropTypes.any


}

export default ProductWebshot;
