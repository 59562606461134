import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label, FormGroup, Form, ListGroup, ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import CustomerSelect from "pages/Orders/customersmodal";
import { getProducts } from "helpers/api_helper";

const LoanModaladd = ({ isOpen, toggle, customers, postLoan }) => {
  const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState(""); // Store selected customer name
  const [prdId, setPrdId] = useState("");
  const [prdName, setPrdName] = useState(""); // Store selected product name
  const [quantity, setQuantity] = useState("");
  const [products, setProductsList] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    getProducts(null, null).then(res => setProductsList(res));
  }, []);

  // Handle customer selection
  const handleCustomerSelect = (selectedId) => {
    setCustomerId(selectedId);
    const selectedCustomer = customers.find((cust) => cust.id === selectedId);
    setCustomerName(selectedCustomer ? selectedCustomer.name : "");
  };

  // Handle product search input
  const handleProductSearch = (e) => {
    const searchValue = e.target.value.trim().toLowerCase();
    setPrdName(searchValue);
  
    if (!searchValue) {
      setFilteredProducts([]);
      return;
    }
  
    const matches = products.filter(product =>
      product.name.toLowerCase().includes(searchValue) ||
      (product.sku && product.sku.toLowerCase().includes(searchValue))
    );
  
    setFilteredProducts(matches);
  };

  // Handle product selection from list
  const handleProductSelect = (product) => {
    setPrdId(product.id);
    setPrdName(product.name);
    setFilteredProducts([]); // Hide list after selection
  };

  const handleSubmit = async () => {
    if (!customerId || !prdId || !quantity) {
      alert("Please fill in all fields.");
      return;
    }

    const payload = {
      resource: [
        {
          bound: "OUTBOUND",
          customer_id: customerId,
          prd_id: prdId,
          order_id: 88888,
          quantity: parseInt(quantity),
          custname: customerName, 
          prdname: prdName, // Include selected product name
        }
      ],
    };

    try {
      console.log(payload);
      await postLoan(payload);
      setPrdId("");
      setQuantity("")
      setPrdName("");
      setCustomerName("")
      setCustomerId("")
      setFilteredProducts([]); // Hide list after selection
      toggle(); // Close modal on success
    } catch (error) {
      console.error("Error creating loan record:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Create Loan Record</ModalHeader>
      <ModalBody>
        <Form>
          {/* Customer Selection */}
          <FormGroup>
            <Label>Select Customer</Label>
            <CustomerSelect customers={customers} onSelect={handleCustomerSelect} />
          </FormGroup>

          {customerName && (
            <FormGroup>
              <Label>Selected Customer:</Label>
              <Input type="text" value={customerName} disabled />
            </FormGroup>
          )}

          {/* Product Search & Selection */}
          <FormGroup>
            <Label>Product Name</Label>
            <Input
              type="text"
              value={prdName}
              onChange={handleProductSearch}
              placeholder="Type to search product..."
            />
            {filteredProducts.length > 0 && (
              <ListGroup style={{ maxHeight: "150px", overflowY: "auto", marginTop: "5px" }}>
                {filteredProducts.map((product) => (
                  <ListGroupItem key={product.id} onClick={() => handleProductSelect(product)}>
                    {product.name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </FormGroup>

          {/* Selected Product ID Display */}
          {prdId && (
            <FormGroup>
              <Label>Selected Product ID:</Label>
              <Input type="text" value={prdId} disabled />
            </FormGroup>
          )}

          {/* Quantity Input */}
          <FormGroup>
            <Label>Quantity</Label>
            <Input
              type="number"
              value={quantity}
              min="1"
              onChange={(e) => setQuantity(e.target.value)}
              placeholder="Enter Quantity"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

LoanModaladd.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  customers: PropTypes.array.isRequired,
  postLoan: PropTypes.func.isRequired,
};

export default LoanModaladd;
